import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

// In ChatHeader.js
const UserStatus = {
  ONLINE: 'online',
  AWAY: 'away',
  OFFLINE: 'offline'
};

const getUserStatusColor = (status) => {
  switch (status) {
    case UserStatus.ONLINE:
      return '#44b700';
    case UserStatus.AWAY:
      return '#ffa500';
    case UserStatus.OFFLINE:
      return '#bdbdbd';
    default:
      return '#bdbdbd';
  }
};

const ChatHeader = ({ participantsList, onlineUsers, awayUsers, geekerLogo, softwareName }) => {
  const getUserStatus = (userId) => {
    if (onlineUsers.has(userId)) return UserStatus.ONLINE;
    if (awayUsers.has(userId)) return UserStatus.AWAY;
    return UserStatus.OFFLINE;
  };

  const getStatusText = (status) => {
    switch (status) {
      case UserStatus.ONLINE:
        return 'online';
      case UserStatus.AWAY:
        return 'away';
      case UserStatus.OFFLINE:
        return 'offline';
      default:
        return 'offline';
    }
  };

  return (
    <div className="chat-header d-flex align-items-center pl-3">
      <img src={geekerLogo} className="geek-icon-style" alt="bellIcon" />
      <div className="d-flex flex-column">
        <div className="d-flex flex-wrap row names-style">
          {participantsList.length > 0 &&
            participantsList.map((item, index) => {
              if (item.userType && item.userType !== 'SuperAdmin') {
                const status = getUserStatus(item.userId);
                return (
                  <div className="chatheader d-flex align-items-center" key={index}>
                    <b>{item.name}</b>
                    <CircleIcon
                      sx={{
                        fontSize: '8px',
                        marginLeft: '4px',
                        color: getUserStatusColor(status)
                      }}
                    />
                    <span className="online-status-text ms-1" style={{
                      fontSize: '11px',
                      color: getUserStatusColor(status)
                    }}>
                      {getStatusText(status)}
                    </span>
                  </div>
                );
              }
              return null;
            })}
        </div>
        <span style={{ fontSize: '11px' }}>{softwareName}</span>
      </div>
    </div>
  );
};

// Enhanced message status handler
export const handleMessageStatus = (messageId, status, jobId, userId, recipientId = null) => {
  if (!messageId) return;

  return {
    jobId,
    messageId,
    userId,
    status,
    recipientId,
    timestamp: new Date()
  };
};

// Message status observer setup
export const setupMessageObserver = (ref, messageIdsSet, currentUser, handler) => {
  if (!ref.current) return null;

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const messageId = entry.target.getAttribute('data-message-id');
          const authorId = entry.target.getAttribute('data-author');

          if (messageId && authorId !== currentUser.id && !messageIdsSet.has(messageId)) {
            handler(messageId);
            messageIdsSet.add(messageId);
          }
        }
      });
    },
    { threshold: 0.5 }
  );

  const messageElements = ref.current.querySelectorAll('.message-div[data-message-id]');
  messageElements.forEach(el => observer.observe(el));

  return observer;
};

export default ChatHeader;