import React from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PropTypes from 'prop-types';

const MessageStatus = {
    DELIVERED: 'delivered',
    DELIVERED_NOT_SEEN: 'delivered_not_seen',
    SEEN: 'seen',
};

const MessageStatusIcon = ({ status }) => {
    switch (status) {
        case MessageStatus.DELIVERED:
            return <DoneIcon className="message-status-icon delivered" style={{ color: '#8e8e8e' }} />;
        case MessageStatus.DELIVERED_NOT_SEEN:
            return <DoneAllIcon className="message-status-icon delivered-not-seen" style={{ color: '#bdbdbd' }} />;
        case MessageStatus.SEEN:
            return <DoneAllIcon className="message-status-icon seen" style={{ color: '#34b7f1' }} />;
        default:
            return null;
    }
};

const UrlSegment = ({ url, copyToClipboard }) => (
    <div className="url-container">
        <a style={{ color: '#2196f3' }} href={url} title={url}>
            <span>{url}</span>
        </a>
        <div className="custom-buttons text-center">
            <div className="custom-buttons-inner">
                <span
                    className="button-for-link copy-to-clipboard"
                    onClick={() => copyToClipboard(url)}
                    title="Copy to clipboard"
                >
                    Copy <ContentCopyIcon />
                </span>
                <span
                    className="button-for-link open-in-new-tab"
                    onClick={() => window.open(url, '_blank')}
                    title="Open link in new tab"
                >
                    Open <OpenInNewIcon />
                </span>
            </div>
        </div>
    </div>
);

const TextSegment = ({ text, geekerMsg }) => (
    <span style={{ wordBreak: 'break-word' }}>
        {geekerMsg ? (
            <span style={{ color: '#007d81' }}>{text}</span>
        ) : (
            <span>{text}</span>
        )}
    </span>
);

const UrlifyMessage = ({
    message,
    copyToClipboard,
    formatDateOfTwilioMessage,
    timeStamp,
    geekerMsg,
    messageStatus,
    isSender
}) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const segments = message?.split(urlRegex) || [];

    const transformedText = segments.map((segment, index) => {
        if (segment.match(urlRegex)) {
            return (
                <UrlSegment
                    key={index}
                    url={segment}
                    copyToClipboard={copyToClipboard}
                />
            );
        }
        return (
            <TextSegment
                key={index}
                text={segment}
                geekerMsg={geekerMsg}
            />
        );
    });

    return (
        <div className="message-container">
            {transformedText}
            <div className="message-footer">
                <span className="chat-time-style">
                    {timeStamp && formatDateOfTwilioMessage(timeStamp)}
                </span>
                {isSender && (
                    <MessageStatusIcon status={messageStatus || MessageStatus.DELIVERED} />
                )}
            </div>
        </div>
    );
};

UrlifyMessage.propTypes = {
    message: PropTypes.string,
    copyToClipboard: PropTypes.func.isRequired,
    formatDateOfTwilioMessage: PropTypes.func.isRequired,
    timeStamp: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    geekerMsg: PropTypes.bool,
    messageStatus: PropTypes.oneOf(Object.values(MessageStatus)),
    isSender: PropTypes.bool
};

UrlifyMessage.defaultProps = {
    geekerMsg: false,
    isSender: false
};

export default React.memo(UrlifyMessage);