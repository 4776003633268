import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import waveHand from '../../../assets/images/waving-hand_1f44b 1.png'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import Link from '@mui/material/Link'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import './style.css'

const LeaveMeetingModal = ({
	LeaveTheMeeting,
	setLeaveTheMeeting,
	handleLeaveClick,
	endMeetingConfirm,
	startTroubleShootModal,
	startswitchGeekModalModal,
}) => {
	const handleClose = () => setLeaveTheMeeting(false)

	const handleLeaveMeeting = () => {
		localStorage.removeItem('customerModalAction')
		localStorage.removeItem('customerResponceBackForTransfer')
		localStorage.removeItem('isBackdropVisible')
		localStorage.removeItem('coBrandingLogoUrl')
		localStorage.removeItem('technicianDismissedApproachingEstimatedTime')
		// localStorage.removeItem('firstWarningMessageObject')
		localStorage.removeItem('firstMessageSent')
		setLeaveTheMeeting(false)
		endMeetingConfirm()
	}

	const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}))

	return (
		<>
			<div>
				<Modal
					open={LeaveTheMeeting}
					// onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box className="outer-box-modal">
						<div className="close-icon">
							<CloseIcon onClick={handleClose} className="close-icon-btn" style={{ cursor: 'pointer' }} />
						</div>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							<div className="text-div mg-b-10">
								<div className="end-the-job-div">
									<img src={waveHand} className="img-class" alt="some caption" />
									<span className="close-icon-btn-text" id="automation-end-meeting-btn">
										Are you sure you want to end the job?
									</span>
								</div>
								<div className="return-to-this-div">
									<span className="return-to-this-text">You won’t be able to return to this job.</span>
								</div>
							</div>
						</Typography>

						<Typography id="modal-modal-description" className="buttons-main-div ">
							<div className="d-flex justify-content-around buttons-container mg-b-10 ">
								{startTroubleShootModal || startswitchGeekModalModal ? null : (
									<div className="mg-b-10">
										<Stack spacing={2} direction="row">
											<Button variant="outlined" style={{}} className="Outlined-btn-join" onClick={handleClose}>
												<span className="join-btn-css">Resume Meeting</span>
											</Button>
										</Stack>
									</div>
								)}
								{startTroubleShootModal || startswitchGeekModalModal ? (
									<div className="mg-b-10">
										<Stack spacing={2} direction="row">
											<Button variant="outlined" style={{}} className="Outlined-btn-join" onClick={handleClose}>
												<span className="join-btn-css">Resume Meeting</span>
											</Button>
										</Stack>
									</div>
								) : null}

								<div className="mg-b-10">
									<Stack spacing={2} direction="row">
										<Button variant="contained" onClick={handleLeaveMeeting} className="contained-end-btn">
											<span className="End-Meting-btn-css">End Meeting</span>
										</Button>
									</Stack>
								</div>
							</div>
							{startTroubleShootModal || startswitchGeekModalModal ? null : (
								<div className="link-btn-container">
									<Link component="button" variant="body2" className="link-btn" onClick={handleLeaveClick}>
										Join from a different device
									</Link>
								</div>
							)}
						</Typography>
					</Box>
				</Modal>
			</div>
		</>
	)
}

export default LeaveMeetingModal
