import React, { useState, useRef, useEffect, useCallback } from 'react'
import './twilio-chat-styles.css'
// import { useUser } from '../../context/useContext';
import geekerLogo from '../../assets/images/newChatLogo.png'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import * as TwilioChatApi from '../../api/twilioChatApi'
import * as JobApi from '../../api/job.api'
import { Col, notification, Spin } from 'antd'
import { Client as ConversationsClient } from '@twilio/conversations'
import Loader from '../../components/Common/Loader/index'
import { formatDateOfTwilioMessage, openNotificationWithIcon } from '../../utils'
import documentIcon from '../../assets/images/document.png'
import SendButton from '../../assets/images/paper-plane.png'
import EmptyChat from '../../assets/images/chat.png'
import { SERVER_URL } from '../../constants'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import notificationSound from '../../assets/sounds/notification.mp3'
import useSound from 'use-sound'
import UrlifyMessage from './UrlifyMessage'
import Alert from '@mui/material/Alert';
import { useVolume } from '../../context/VolumeProvider'
import ChatHeader, { setupMessageObserver } from './ChatHeader'

const ChatTextInput = ({ focusToInput, inputRef, disableChatButton, style, keyPress, mediaLoader }) => {
	if (focusToInput) {
		requestAnimationFrame(() => {
			inputRef.current.focus()
		})
	}

	return (
		<textarea
			ref={inputRef}
			type="text"
			placeholder="Type Here...."
			disabled={disableChatButton || mediaLoader}
			style={{
				...style,
				resize: "none",
				maxHeight: "200px",
			}}
			onKeyPress={keyPress}
		>
		</textarea>
	);
}

const TwilioChatPanel = ({ width, height, job, socket, user, userType, setNotificationDot, socketHits, setSocketHits, startChat }) => {
	const [messages, setMessages] = useState([])
	const [loadChat, setLoadChat] = useState(false)
	const [participantsList, setParticipantsList] = useState([])
	const [conversationProxy, setConversationProxy] = useState()
	const [chatStatus, setChatStatus] = useState({ statusString: '', status: '' })
	const [refreshSocket, setRefreshSocket] = useState(false)
	const [scrollToChat, setScrollToChat] = useState(false)
	const [prepareFileToSend, setPrepareFileToSend] = useState()
	const [mediaLoader, setMediaLoader] = useState(false)
	const [disableChatButton, setDisableChatButton] = useState(false)
	const [timeStampRefresh, setTimeStampRefresh] = useState(false)
	const [chatServiceSid, setChatServiceSid] = useState()
	const [shouldFocusInputRef, setShouldFocusInputRef] = useState(false)
	const [loadMoreChat, setLoadMoreChat] = useState(false)
	const [nextPageUrl, setNextPageUrl] = useState()
	const [copySuccess, setCopySuccess] = useState(false);
	const { effectiveVolume } = useVolume();
	const [play] = useSound(notificationSound, { volume: effectiveVolume })
	const [messageStatuses, setMessageStatuses] = useState({});
	const [onlineUsers, setOnlineUsers] = useState(new Set());
	const [awayUsers, setAwayUsers] = useState(new Set());
	const [isVisible, setIsVisible] = useState(true);
	const chatContainerRef = useRef(null);
	const inputRef = useRef(null);
	const fileInputRef = useRef(null);
	const messageStatusesRef = useRef(messageStatuses);
	const emailTimersRef = useRef({});

	useEffect(() => {
		messageStatusesRef.current = messageStatuses;
	}, [messageStatuses]);

	useEffect(() => {
		const handleBeforeUnload = () => {
			if (socket && job?.id) {
				socket.emit('user-status', {
					jobId: job.id,
					userId: user.id,
					status: 'offline'
				});
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		if (socket && job?.id) {
			socket.emit('user-connected', {
				jobId: job.id,
				userId: user.id
			});
		}

		return () => {
			handleBeforeUnload();
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [socket, job?.id, user.id]);

	useEffect(() => {
		return () => {
			Object.values(emailTimersRef.current).forEach(timer => {
				clearTimeout(timer);
			});
			emailTimersRef.current = {};
		};
	}, []);

	useEffect(() => {
		if (isVisible) {
			messages.forEach(message => {
				if (message.author !== user.id && messageStatuses[message.messageId] !== 'seen') {
					handleMessageSeen(message.messageId);
				}
			});
		}
	}, [isVisible, messages, messageStatuses]);

	useEffect(() => {
		messages.forEach(msg => {
			const recipientId = msg.author === user.id
				? msg.recipient
				: msg.author;

			handleMessageStatus(
				msg.messageId,
				messageStatuses[msg.messageId] || 'delivered',
				recipientId
			);
		});
	}, [onlineUsers, awayUsers]);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (!socket || !job?.id) return;

			const newVisibility = !document.hidden;
			setIsVisible(newVisibility);

			const updateStatuses = (shouldBeSeen) => {
				setMessageStatuses(prev => {
					const updated = { ...prev };
					messages.forEach(msg => {
						if (msg.author !== user.id) {
							const current = updated[msg.messageId];
							if (shouldBeSeen) {
								if (current === 'delivered' || current === 'delivered_not_seen') {
									updated[msg.messageId] = 'seen';
								}
							} else {
								if (current === 'delivered' || current === 'seen') {
									updated[msg.messageId] = 'delivered_not_seen';
								}
							}
						}
					});
					return updated;
				});
			};

			if (newVisibility) {
				socket.emit('user-status', {
					jobId: job.id,
					userId: user.id,
					status: 'online'
				});
				updateStatuses(true);
			} else {
				socket.emit('user-status', {
					jobId: job.id,
					userId: user.id,
					status: 'away'
				});
				updateStatuses(false);
			}
		};

		const handleWindowFocus = () => {
			if (socket && job?.id) {
				socket.emit('user-status', {
					jobId: job.id,
					userId: user.id,
					status: 'online'
				});
			}
		};

		const handleWindowBlur = () => {
			if (socket && job?.id) {
				socket.emit('user-status', {
					jobId: job.id,
					userId: user.id,
					status: 'away'
				});
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);
		window.addEventListener('focus', handleWindowFocus);
		window.addEventListener('blur', handleWindowBlur);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
			window.removeEventListener('focus', handleWindowFocus);
			window.removeEventListener('blur', handleWindowBlur);
		};
	}, [socket, job?.id, user.id, messages]);

	useEffect(() => {
		if (!socket || !job?.id) return;

		const handleStatusUpdate = (data) => {
			if (data.jobId === job.id) {
				const { onlineUsers: updatedOnlineUsers, awayUsers: updatedAwayUsers } = data;

				setOnlineUsers(new Set(updatedOnlineUsers));
				setAwayUsers(new Set(updatedAwayUsers));

				// Re-evaluate message statuses when user status changes
				const updatedStatuses = { ...messageStatuses };
				messages.forEach(message => {
					if (message.author !== user.id) {
						const currentStatus = updatedStatuses[message.messageId];
						if (currentStatus === 'delivered' || currentStatus === 'delivered_not_seen') {
							updatedStatuses[message.messageId] = 'seen';
						}
					}
				});
				setMessageStatuses(updatedStatuses);
			}
		};

		socket.on('user-status-update', handleStatusUpdate);

		return () => {
			socket.off('user-status-update', handleStatusUpdate);
		};
	}, [socket, job?.id, messages, messageStatuses]);


	const handleMessageSeen = (messageId) => {
		if (!messageId || messageStatuses[messageId] === 'seen') return;

		setMessageStatuses(prev => ({
			...prev,
			[messageId]: 'seen'
		}));

		socket.emit('message-status', {
			jobId: job.id,
			messageId,
			userId: user.id,
			status: 'seen',
			timestamp: new Date()
		});
	};

	const handleMessageStatus = (messageId, status, recipientId = null) => {
		if (!messageId || messageStatusesRef.current[messageId] === status) return;

		if (messageStatusesRef.current[messageId] === 'seen') {
			return;
		}

		let finalStatus = status;

		if (recipientId) {
			const isMyMessage = messages.find(m => m.messageId === messageId)?.author === user.id;

			if (isMyMessage) {
				if (!onlineUsers.has(recipientId) && !awayUsers.has(recipientId)) {
					finalStatus = 'delivered';
				} else if (awayUsers.has(recipientId)) {
					finalStatus = 'delivered_not_seen';
				} else if (onlineUsers.has(recipientId)) {
					finalStatus = 'seen';
				}
			} else {
				finalStatus = isVisible ? 'seen' : 'delivered_not_seen';
			}
		}

		setMessageStatuses(prev => {
			const newStatuses = {
				...prev,
				[messageId]: finalStatus
			};
			messageStatusesRef.current = newStatuses;
			return newStatuses;
		});

		// Emit status update
		if (socket && job?.id) {
			socket.emit('message-status', {
				jobId: job.id,
				messageId,
				userId: user.id,
				status: finalStatus,
				recipientId,
				timestamp: new Date()
			});
		}
	};

	useEffect(() => {
		if (conversationProxy) {
			conversationProxy.on('messageAdded', async (data) => {
				const messageId = data.state.sid;
				const localMessageId = data.state.attributes.localMessageId;
				const recipientId = data.state.attributes.recipient;

				let imageUrl = data.state.body === 'file has been uploaded' && data.state.media
					? await data.state.media.getContentTemporaryUrl()
					: false;

				if (data.state?.author !== user.id) {
					setSocketHits((prevHits) => prevHits + 1);
					play();
					notification.destroy();
					notification.info({
						key: 'newMessageReceived',
						duration: 4,
						message: `New chat message received`
					});
					setNotificationDot(true);
				}

				const newMessage = {
					senderName: data.state.attributes.userName,
					text: data.state.body,
					author: data.state?.author,
					timeStamp: data.state.attributes.timeStamp,
					imageUrl,
					content_type: data.state.body === 'file has been uploaded' && data.state.media
						? data.state.media.contentType
						: false,
					mediaDetails: data.state.body === 'file has been uploaded' && data.state.media
						? { chatServiceSid: chatServiceSid, mediaSid: data.state.media?.state?.sid }
						: {},
					messageId,
					localMessageId,
					status: 'delivered',
					recipient: userType === 'technician' ? job.customer.user.id : job.technician.user.id
				};

				// Update messages array
				setMessages(prev => {
					if (localMessageId) {
						const index = prev.findIndex(m => m.localMessageId === localMessageId);
						if (index !== -1) {
							const updatedMessages = [...prev];
							updatedMessages[index] = newMessage;
							return updatedMessages;
						}
					}
					const exists = prev.find(m => m.messageId === messageId);
					if (!exists) {
						return [newMessage, ...prev];
					}
					return prev;
				});

				if (data.state?.author === user.id) {
					let initialStatus = 'delivered';

					if (!onlineUsers.has(recipientId) && !awayUsers.has(recipientId)) {
						initialStatus = 'delivered';
					} else if (awayUsers.has(recipientId)) {
						initialStatus = 'delivered_not_seen';
					} else if (onlineUsers.has(recipientId)) {
						initialStatus = 'delivered';
					}

					handleMessageStatus(messageId, initialStatus, recipientId);
				} else {
					const status = isVisible && onlineUsers.has(user.id) ? 'seen' : 'delivered_not_seen';
					handleMessageStatus(messageId, status, data.state?.author);
				}

				if (data.state?.author === user.id) {
					const lastReadMessageIndex = data?.state?.index;
					conversationProxy.updateLastReadMessageIndex(lastReadMessageIndex);
				}

				setMediaLoader(false);
			});

			return () => {
				conversationProxy.removeAllListeners('messageAdded');
			};
		}
	}, [conversationProxy]);

	const messageObserverCallback = useCallback((messageId, authorId) => {
		const recipientStatus =
			onlineUsers.has(authorId) ? 'online' :
				awayUsers.has(authorId) ? 'away' : 'offline';

		handleMessageStatus(
			messageId,
			recipientStatus === 'online' ? 'seen' : 'delivered_not_seen',
			authorId
		);
	}, [onlineUsers, awayUsers]);

	useEffect(() => {
		const messageIds = new Set();
		const observer = setupMessageObserver(
			chatContainerRef,
			messageIds,
			user,
			messageObserverCallback
		);

		return () => {
			if (observer) {
				observer.disconnect();
				messageIds.clear();
			}
		};
	}, [messages, isVisible, messageObserverCallback]);

	useEffect(() => {
		socket.on('message-status-update', (data) => {
			if (data.jobId === job.id) {
				setMessageStatuses(prev => ({
					...prev,
					[data.messageId]: data.status
				}));
			}
		});

		return () => {
			socket.off('message-status-update');
		};
	}, [socket, job.id]);


	useEffect(() => {
		fetchPreviousChat()
	}, [])

	useEffect(() => {
		if (startChat != 'not-Set') {
			setShouldFocusInputRef(true)
		}
	}, [startChat])

	useEffect(() => {
		let timeoutId;
		if (copySuccess) {
			timeoutId = setTimeout(() => {
				setCopySuccess(false);
			}, 1000);
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, [copySuccess]);


	useEffect(() => {
		const interval = setInterval(() => {
			setTimeStampRefresh((prevValue) => !prevValue)
		}, 60000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (job) {
			fetchChatOrCreate()
		}
	}, [])

	useEffect(() => {
		socket.on('refresh-twilio-chat-panel-send', (data) => {
			if (data.job === job?.id) {
				setRefreshSocket(true)
				setTimeout(function () {
					setScrollToChat(true)
				}, 500)
			}
		})
	}, [socket])

	useEffect(() => {
		if (scrollToChat) {
			if (chatContainerRef && chatContainerRef.current) {
				const chatContainer = chatContainerRef.current
				if (chatContainer) {
					chatContainer.scrollTop = chatContainer.scrollHeight
					setScrollToChat(false)
				}
			}
		}
	}, [scrollToChat, messages])

	const handleChatScroll = async (e) => {
		if (!loadMoreChat && e.target.scrollTop < 60) {
			setLoadMoreChat(true)
			if (nextPageUrl != null) {
				await fetchPreviousChatOnScroll()
			}
			setLoadMoreChat(false)
		}
	}

	const handleChatScrollButton = async (e) => {
		setLoadMoreChat(true)
		if (nextPageUrl != null) {
			await fetchPreviousChatOnScroll()
		}
		setLoadMoreChat(false)
	}

	const fetchChatOrCreate = async () => {
		try {
			setLoadChat(true)
			let twilioData = {
				chatId: job ? job.id : null,
			}
			const responceChat = await TwilioChatApi.fetchTwilioConversation(twilioData)
			if (responceChat.twilioData.success) {
				const sid = responceChat?.twilioData?.conversation?.sid
				const chatServiceSid = responceChat?.twilioData?.conversation?.chatServiceSid
				setChatServiceSid(chatServiceSid)
				if (sid && chatServiceSid) {
					const addParticipat = await TwilioChatApi.addTwilioParticiants({
						conversationSid: sid,
						userDetails: user,
						chatServiceSid: chatServiceSid,
					})
					if (messages.length <= 0) {
						await fetchPreviousChat()
					}
					ChatParticiantsList(sid)
					userChatStatus(addParticipat?.twilioData?.token)
					setLoadChat(false)
				}
			}
			setLoadChat(false)
			setScrollToChat(false)
		} catch (err) {
			console.log('error in fetchChatOrCreate', err)
			setLoadChat(false)
			setScrollToChat(false)
		}
	}

	useEffect(() => {
		if (conversationProxy) {
			setTimeout(function () {
				setLoadChat(false)
				setScrollToChat(true)
			}, 1000)
		}
	}, [conversationProxy])

	const fetchPreviousChat = async () => {
		if (job) {
			let dataToSend = { chat_id: job.id };
			const chatResponse = await TwilioChatApi.getTwilioChatDetails(dataToSend);
			const twilioChatSid = chatResponse?.conversation[0]?.twilio_chat_service?.sid;
			const twilioChatServiceId = chatResponse?.conversation[0]?.twilio_chat_service?.chatServiceSid;

			if (chatResponse?.conversation?.length > 0 && twilioChatSid && twilioChatServiceId) {
				const chatDetails = await TwilioChatApi.getTwilioChat(twilioChatSid, twilioChatServiceId);
				if (chatDetails.formattedResponse.length > 0) {
					setMessages(chatDetails.formattedResponse);
					setNextPageUrl(chatDetails.nextPageUrl);

					const initialStatuses = {};
					chatDetails.formattedResponse.forEach(message => {
						const recipientId = message.author === user.id
							? (userType === 'technician'
								? job.customer.user.id
								: job.technician.user.id)
							: message.author;

						const isRecipientOnline = onlineUsers.has(recipientId);
						const isRecipientAway = awayUsers.has(recipientId);

						if (message.author === user.id) {
							initialStatuses[message.messageId] =
								isRecipientOnline ? 'seen' :
									isRecipientAway ? 'delivered_not_seen' : 'delivered';
						} else {
							initialStatuses[message.messageId] =
								isVisible ? 'seen' : 'delivered_not_seen';
						}
					});
					setMessageStatuses(initialStatuses);
					messageStatusesRef.current = initialStatuses;
				}
			}
		}
	};

	const fetchPreviousChatOnScroll = async () => {
		let dataToSend = { chat_id: job.id }
		const chatResponce = await TwilioChatApi.getTwilioChatDetails(dataToSend)
		const twilioChatSid = chatResponce?.conversation[0].twilio_chat_service?.sid
		const twiliochatServiceId = chatResponce?.conversation[0]?.twilio_chat_service?.chatServiceSid

		if (chatResponce?.conversation?.length > 0 && twilioChatSid && twiliochatServiceId && nextPageUrl != null) {
			const chatDetails = await TwilioChatApi.getTwilioChat(twilioChatSid, twiliochatServiceId, nextPageUrl)

			if (chatDetails.formattedResponse.length > 0) {
				let previousMessages = [...messages]
				previousMessages.push(...chatDetails.formattedResponse)
				setNextPageUrl(chatDetails.nextPageUrl)
				setMessages(previousMessages)
			}
		}
	}

	const ChatParticiantsList = async (sid) => {
		try {
			const particiantsList = await TwilioChatApi.twilioParticiantsList({ conversationSid: sid })
			console.log('particiantsList of Twilio Chat :::::', particiantsList)
			let particiantsAttribute = []
			particiantsList.twilioData.participants.forEach((particiant) => {
				if (JSON.parse(particiant.attributes).userId !== user.id) {
					particiantsAttribute.push(JSON.parse(particiant.attributes))
				}
			})
			setParticipantsList(particiantsAttribute)
		} catch (error) {
			console.log('error while fetching participantlist', error)
			return
		}
	}

	const userChatStatus = (token) => {
		try {
			const conversationsClient = new ConversationsClient(token)
			conversationsClient.on('connectionStateChanged', (state) => {
				if (state === 'connecting')
					setChatStatus({
						statusString: 'Connecting to Chat ...',
						status: 'default',
					})
				if (state === 'connected') {
					setChatStatus({
						statusString: 'You are connected.',
						status: 'success',
					})
					let warningMessage = null;
					if (!localStorage.getItem('firstMessageSent')) {
						let text = 'Welcome to Geeker, we\'re excited to help you!\nPlease use this chat to communicate and share any information, files or links related to the task.'
						if (userType === 'technician') {
							text += '\nReminder: To ensure security and protect customer data, all communication must stay within the Geeker platform. Do not use personal emails, phone numbers, or external apps for any interactions.'
						}
						warningMessage = {
							senderName: "Geeker",
							text: text,
							timeStamp: new Date(),
							geekerMsg: true,
						};
						localStorage.setItem('firstMessageSent', true)
					}
					if (warningMessage) {
						setMessages((prevMessages) => [warningMessage, ...prevMessages]);
					}
				}
				if (state === 'denied')
					setChatStatus({
						statusString: 'Facing issue while connecting your chat. Please refresh the page and try again',
						status: 'error',
					})
			})
			conversationsClient.on('conversationJoined', (conversation) => {
				const friendlyName = conversation.friendlyName
				if (friendlyName == job?.id) {
					setConversationProxy(conversation)
				}
			})
		} catch (error) {
			setChatStatus({
				statusString: 'Facing issue while connecting your chat. Please refresh the page and try again',
				status: 'error',
			})
		}
	}

	const copyToClipboard = useCallback(async (url) => {
		try {
			await navigator.clipboard.writeText(url);
			setCopySuccess(true);
			setTimeout(() => setCopySuccess(false), 1000);
		} catch (err) {
			console.error('Unable to copy text to clipboard:', err);
			setCopySuccess(false);
		}
	}, []);

	const handleSendMessage = async () => {
		if (!conversationProxy || (!inputRef.current?.value.trim() && !prepareFileToSend)) return;

		const messageText = inputRef.current?.value.trim();
		const localMessageId = Math.random().toString(36).substr(2, 9);
		const recipientId = userType === 'technician' ? job.customer.user.id : job.technician.user.id;

		try {
			setDisableChatButton(true);
			let sentMessage;

			const tempMessage = {
				senderName: `${user.firstName} ${user.lastName}`,
				text: prepareFileToSend ? 'file has been uploaded' : messageText,
				author: user.id,
				timeStamp: new Date(),
				localMessageId,
				messageId: null,
				status: 'sending',
				recipient: recipientId
			};

			setMessages(prev => [tempMessage, ...prev]);

			const messageAttributes = {
				timeStamp: new Date(),
				userName: `${user.firstName} ${user.lastName}`,
				email: user.email,
				localMessageId,
				recipient: recipientId
			};

			if (prepareFileToSend) {
				setMediaLoader(true);
				sentMessage = await conversationProxy
					.prepareMessage()
					.setBody('file has been uploaded')
					.addMedia(prepareFileToSend)
					.setAttributes(messageAttributes)
					.build()
					.send();

				setPrepareFileToSend(null);
				fileInputRef.current.value = null;
			} else {
				sentMessage = await conversationProxy
					.sendMessage(messageText, messageAttributes);
			}
			inputRef.current.value = '';

			let initialStatus;
			if (!onlineUsers.has(recipientId) && !awayUsers.has(recipientId)) {
				initialStatus = 'delivered';
			} else if (awayUsers.has(recipientId)) {
				initialStatus = 'delivered_not_seen';
			} else {
				initialStatus = 'seen';
			}

			if (sentMessage?.sid) {
				handleMessageStatus(sentMessage.sid, initialStatus, recipientId);

				if (!onlineUsers.has(recipientId) && !awayUsers.has(recipientId)) {
					if (emailTimersRef.current[sentMessage.sid]) {
						clearTimeout(emailTimersRef.current[sentMessage.sid]);
					}

					emailTimersRef.current[sentMessage.sid] = setTimeout(() => {
						const currentStatus = messageStatusesRef.current[sentMessage.sid];
						if (currentStatus === 'delivered') {
							socket.emit('send-chat-email', {
								jobId: job.id,
								messageId: sentMessage.sid,
								senderId: user.id,
								userType,
								timestamp: new Date(),
								meetingLink: window.location.href,
								customerName: job.customer.user.firstName,
								technicianName: job.technician.user.firstName,
								softwareName: job.software.name,
								customerPhone: job.customer.phoneNumber,
								technicianPhone: job.technician.phoneNumber,
							});
						}
					}, 2 * 60 * 1000);
				}
			}

			setScrollToChat(true);

		} catch (error) {
			console.error('Error sending message:', error);
			setMessages(prev => prev.filter(m => m.localMessageId !== localMessageId));
			setMessageStatuses(prev => {
				const newStatuses = { ...prev };
				delete newStatuses[localMessageId];
				return newStatuses;
			});
		} finally {
			setDisableChatButton(false);
			setMediaLoader(false);
			setShouldFocusInputRef(true);
		}
	};

	const containerStyle = {
		width: width,
		height: height,
		border: '1px solid #ccc',
		backgroundColor: '#fff',
		boxShadow: `rgb(136, 136, 136) 1px 1px 3px`,
	}

	const handleFileInputChange = async (event) => {
		setScrollToChat(true)
		const selectedFile = event.target.files[0]

		if (selectedFile.size > 15 * 1024 * 1024) {
			openNotificationWithIcon('error', 'Error', 'File size cannot exceed 15 MB.')
			return
		}
		var formdata = new FormData()
		formdata.append('file', selectedFile)
		setPrepareFileToSend(formdata)
		inputRef.current.value = selectedFile.name
	}

	const handleIconClick = () => {
		fileInputRef.current.click()
	}

	if (loadChat) {
		return (
			<div className="loader-name-style" style={{ ...containerStyle }}>
				<h5 className="d-flex flex-column mb-3">{chatStatus.statusString ? chatStatus.statusString : 'Loading Chat'}</h5>
				<Spin style={{ fontSize: '20px' }} />
			</div>
		)
	}
	return (
		<>
			<div className="d-flex flex-column" style={{ ...containerStyle }}>
				<ChatHeader
					participantsList={participantsList}
					onlineUsers={onlineUsers}
					awayUsers={awayUsers}
					geekerLogo={geekerLogo}
					softwareName={job?.software?.name}
				/>
				{copySuccess &&
					<Alert severity="success">link has been copied.</Alert>
				}
				<div
					id="scrollToChat"
					onScroll={handleChatScroll}
					ref={chatContainerRef}
					className="chat-display-box chat-container justify-content-end"
					style={{ height: `calc(${height} - 110px)`, overflowY: 'scroll' }}
				>
					{loadMoreChat && (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<CircularProgress />
						</Box>
					)}
					{nextPageUrl && (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Button onClick={handleChatScrollButton}>load more</Button>
						</Box>
					)}
					{messages && messages.length > 0 ? (
						<div className="d-flex flex-column-reverse justify-content-start" style={{ height: 'auto', minHeight: '100%' }}>
							{messages.map((message, index) =>
								message?.author === user.id ? (
									<div
										key={index}
										className={`message-div ${message.author === user.id ? 'align-self-end' : ''}`}
										data-message-id={message.messageId || message.state?.sid}
										data-author={message.author}
									>
										<div style={{ margin: 0, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
											<h6 className="chat-customer-name">{message?.senderName}</h6>
										</div>
										{message?.imageUrl ? (
											<a
												href={`${SERVER_URL}/twilio-chat/get-media-link-updated?chatServiceSid=${message?.mediaDetails?.chatServiceSid}&mediaSid=${message?.mediaDetails?.mediaSid}`}
												target="_blank"
												className="chat-image-style"
											>
												{message?.imageUrl && message.content_type.includes('image/') ? (
													<img src={message?.imageUrl} style={{ maxHeight: '80px' }} />
												) : (
													<img src={documentIcon} style={{ maxHeight: '80px' }} />
												)}
												<p className="media-name-style">{message.mediaName}</p>
											</a>
										) : (
											<div
												className={`me-chat-div`}
												style={{
													display: "flex",
													justifyContent: "flex-end",
													flexDirection: "column",
												}}
											>
												<UrlifyMessage
													message={message?.text}
													copyToClipboard={copyToClipboard}
													formatDateOfTwilioMessage={formatDateOfTwilioMessage}
													timeStamp={message?.timeStamp}
													geekerMsg={message?.geekerMsg}
													messageStatus={messageStatuses[message.messageId]}
													isSender={message.author === user.id}
												/>
											</div>
										)}
									</div>
								) : (
									<div key={index} className="message-div d-flex flex-column">
										<h6 className="chat-customer-name">{message?.senderName}</h6>
										{message?.imageUrl ? (
											<a
												href={`${SERVER_URL}/twilio-chat/get-media-link-updated?chatServiceSid=${message?.mediaDetails?.chatServiceSid}&mediaSid=${message?.mediaDetails?.mediaSid}`}
												target="_blank"
											>
												{message?.imageUrl && message.content_type.includes('image/') ? (
													<img src={message?.imageUrl} style={{ maxHeight: '80px' }} />
												) : (
													<img src={documentIcon} style={{ maxHeight: '80px' }} />
												)}
												<p className="media-name-style">{message.mediaName}</p>
											</a>
										) : (
											<div
												className={`me-chat-div`}
												style={{
													display: "flex",
													justifyContent: "flex-end",
													flexDirection: "column",
												}}
											>
												<UrlifyMessage
													message={message?.text}
													copyToClipboard={copyToClipboard}
													formatDateOfTwilioMessage={formatDateOfTwilioMessage}
													timeStamp={message?.timeStamp}
													geekerMsg={message?.geekerMsg}
													messageStatus={messageStatuses[message.messageId]}
													isSender={message.author === user.id}
												/>
											</div>
										)}
									</div>
								),
							)}

							{mediaLoader && (
								<div className="d-flex align-self-end " style={{ marginRight: '20px' }}>
									<Loader height="50%" />
								</div>
							)}
						</div>
					) : (
						<NochatAvailableDesign userType={userType} />
					)}
				</div>

				<div className="chat-input-box d-flex align-items-center">
					<ChatTextInput
						inputRef={inputRef}
						disableChatButton={disableChatButton}
						mediaLoader={mediaLoader}
						focusToInput={shouldFocusInputRef}
						style={{
							width: '90%',
							height: '100%',
							padding: '10px',
							border: 'none',
							outline: 'none',
						}}
						keyPress={(e) => {
							if (e.key === 'Enter') {
								handleSendMessage()
							}
						}}
					/>

					<input
						type="file"
						id="attach-file"
						disabled={mediaLoader}
						style={{ display: 'none' }}
						onChange={handleFileInputChange}
						ref={fileInputRef}
					/>
					<IconButton
						aria-label="attach-file"
						component="span"
						onClick={handleIconClick}
						disabled={mediaLoader}
						style={{ transform: 'rotate(45deg)', marginRight: '10px' }}
					>
						<AttachFileIcon />
					</IconButton>

					<IconButton
						style={{ backgroundColor: '#1bd4d5', color: 'white', marginRight: '15px' }}
						onClick={handleSendMessage}
						disabled={disableChatButton || mediaLoader}
					>
						<SendIcon />
					</IconButton>
				</div>
			</div>
		</>
	)
}

const NochatAvailableDesign = ({ userType }) => {
	return (
		<div className="d-flex align-items-center justify-content-center flex-column" style={{ height: '100%' }}>
			<img src={EmptyChat} className="no-chat-available-css" />
			<p className="empty-chat-message">
				{userType == 'customer' ? 'Connect with geeker  for assistance.' : 'Connect with customer for assistance.'}{' '}
			</p>
		</div>
	)
}

export default React.memo(TwilioChatPanel);
