import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Alert, Col, Row, Button } from 'react-bootstrap'
import Timer from 'react-compound-timer'
import { useLocation, useParams } from 'react-router-dom'
import useSound from 'use-sound'
import { getJobInformation, getTotalPaidJobs, sendDataSession, updateJob, createSession } from '../../api/meeting.api'
import { getCustomer } from '../../api/customer.api'
import { getUserByParam } from '../../api/user.api'
import notificationSound from '../../assets/sounds/notification.mp3'
import { DATE_OPTIONS, JOB_STATUS, MAIN_APP_URL, SESSION_EXPIRY_MSG, PARTNER_NAME } from '../../constants'
import '../../logrocketSetup'
import { checkCustomerHaveCard } from '../../services/FreeMinutePopupService'
import { endMeeting } from '../../services/MeetingServices'
import { getSocketClient } from '../../services/SocketServices'

import {
	checkIsCustomer,
	clearTimeOutAndIntervals,
	getCookie,
	isLiveUser,
	muteMicrophone,
	openNotificationWithIcon,
	sendNotificationToTechnician,
	setCookie,
	updateCustomerStartMeetingResponse,
	startTimer,
} from '../../utils'
import CustomerSidebar from '../Common/CustomerSidebar'
import UploadFileModal from '../Common/UploadFileModal'
import Header from '../Common/Header'
import TechnicianSidebar from '../Common/TechnicianSidebar'
import FreeMinutesEndPopup, { SuggestLongJobConversion } from './components/FreeMinutesEndPopup'

import JitsiContainer from './components/JitsiContainer'
import MeetingControllers from './components/MeetingControllers'
import MeetingInfo from './components/MeetingInfo'
import LogRocket from 'logrocket'
import mixpanel from 'mixpanel-browser'
import * as JobCycleApi from '../../api/jobCycle.api'
import {
	FREE_TIME_EXPIRING_SHOW_AFTER_SECONDS,
	JobTags,
	MEETING_END_TIME_SECONDS,
	POPUP_SHOW_AFTER_SECONDS,
	TOTAL_FREE_SECONDS,
	SECRET_KEY,
} from '../../constants'
import * as FullStory from '@fullstory/browser'
import * as CustomerApi from '../../api/customer.api'
import * as MeetingApi from '../../api/meeting.api'
import MeetingStatDetails from '../Common/MeetingStatDetails'
import TwilioChatPanel from '../../components/TwilioChatPanel/TwilioChatPanel'
import { checkMediaPermission } from '../../utils/microphoneStatus'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMediaQuery } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Badge from '@mui/material/Badge'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Modal, notification, Tooltip } from 'antd'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useJitsiMeet } from '../../context/jitsiMeetContext'
import { decryptStripeKey } from '../../utils/decryption'
import UpdateJobModel from '../Common/UpdateJobModel'
import CustomerMeetingConfirmationModal from './components/CustomerMeetingConfirmationModal'
import LetsTroubleShootModal from './components/LetsTroubleshootModal'
import SwitchGeeksModal from './components/SwitchGeeksModal'
import * as JobFileApi from '../../api/jobfile.api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointRight, faShare, faThumbsUp, faTimes, faInfoCircle, faQuestionCircle, faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import WelcomePartnerPopup from './components/WelcomePartnerPopup'
import { getPartnerWithShortName } from '../../api/partner.api'
import { useVolume } from '../../context/VolumeProvider'
import startSmiley from '../../assets/images/star-smiley.png'
import { takeChargeFromCustomer } from '../../api/stripe.api'
import { Collapse } from '@mui/material';

const jitsiContainerId = '6063bd22202fb514ce26346b123'
let techNotified = false
let soundPlayed = false
const time = { seconds: 0, minutes: 0, hours: 0 }
let zohoSession = false
let technicianSession = false
let notifyCustomer = true

export const RemoteAccessModal = ({ visible, onClose }) => {
	const [showLearnMore, setShowLearnMore] = useState(false);

	return (
		<>
			<Modal
				visible={visible}
				width={showLearnMore ? 1200 : 600}
				footer={null}
				onCancel={onClose}
				title={
					<div className="flex flex-wrap items-center justify-between mb-2 text-lg font-semibold">
						<div className="w-full sm:w-auto flex items-center justify-between">
							<span>Remote Access Connection</span>
							{" "}
							<Tooltip
								title={
									"This step allows your Geek to securely connect to your computer to assist you remotely. Enter the code provided by the geek and click the arrow to continue."}
								placement="right"
							>
								<FontAwesomeIcon
									icon={faInfoCircle}
									className="text-gray-500 cursor-pointer ml-2"
									style={{ fontSize: '1.25rem' }}
								/>
							</Tooltip>
						</div>
						<div className="w-full sm:w-auto flex items-center justify-between mt-2 sm:mt-0">
							<span className="ml-2"
								style={{
									cursor: "pointer",
									fontSize: "0.875rem",
									textDecoration: "underline",
								}}
								onClick={() => setShowLearnMore(!showLearnMore)}>Learn More</span>
							{" "}
							<FontAwesomeIcon
								icon={faQuestionCircle}
								className="text-gray-500 cursor-pointer ml-2"
								style={{ fontSize: '1.25rem' }}
							/>
						</div>
					</div>
				}
			>
				<div
					style={{
						display: "flex",
						flexDirection: window.innerWidth < 768 ? "column" : "row",
						gap: "1rem",
					}}
				>
					<div style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						width: showLearnMore ? "50%" : "100%",
						...(window.innerWidth < 768 && { width: "100%" }),
					}}>
						<iframe
							src="https://geeker.screenconnect.com/?Theme=GeekerMeeting"
							className="iframe-screenconnect"
							title="Remote Access Connection"
						/>
					</div>
					{showLearnMore && (
						<div className="mt-4 bg-gray-50 rounded-lg p-4" style={{ width: window.innerWidth < 768 ? "100%" : "50%" }}>
							{/* <FontAwesomeIcon
								icon={faTimes}
								className="text-gray-500 cursor-pointer"
								style={{ fontSize: '1.25rem', position: "absolute", right: "1rem", top: "1rem" }}
								onClick={setShowLearnMore(false)}
							/> */}
							<h3 className="font-semibold mb-3">Remote Connection Explained</h3>

							<div className="space-y-4">
								<div>
									<h4 className="font-medium mb-1">What is Remote Assistance?</h4>
									<p className="text-gray-600">
										Remote assistance allows your Geek to securely connect to your computer to help troubleshoot and solve your issue.
										This is done with your permission, and you are always in control of the connection.
									</p>
								</div>

								<div>
									<h4 className="font-medium mb-1">How Does It Work?</h4>
									<p className="text-gray-600">
										Your Geek provides you with a secure code. Enter the code in the provided field and click the arrow button.
										Once the connection is established, your technician will assist you remotely. You can end the session at any time.
									</p>
								</div>

								<div>
									<h4 className="font-medium mb-1">Is It Safe?</h4>
									<p className="text-gray-600">
										Yes. Our platform uses encrypted connections to ensure your privacy and security.
										Only the Geek you authorize will have access to your computer during the session.
									</p>
								</div>

								<div className="pt-2">
									<p className="text-sm text-gray-500">
										Need More Help? Contact our support team for further guidance.
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</Modal>
		</>
	);
};

const Meeting = () => {
	const { jobId } = useParams()
	const { effectiveVolume } = useVolume()
	const [play] = useSound(notificationSound, { volume: effectiveVolume })
	const { userTypeParams } = useParams()
	const [userType, setUserType] = useState(userTypeParams)
	const [job, setJob] = useState(null)
	const [jitsiSessionEnd, setJitsiSessionEnd] = useState(false)
	const [totalPaidJobs, setTotalPaidJobs] = useState(-1)
	const [user, setUser] = useState(null)
	const [customerInfo, setCustomerInfo] = useState(null)
	const [socket, setSocket] = useState(null)
	const [jitsiApi, setJitsiApi] = useState(null)
	const [userId, setUserId] = useState(null)
	const [showFreeMinutesEndPopup, setShowFreeMinutesEndPopup] = useState(false)
	const [showLastMinuteWarningPopup, setShowLastMinuteWarningPopup] = useState(false)
	const [showFreeMinutesExpiringPopup, setShowFreeMinutesExpiringPopup] = useState(false)
	const [closeWarningPopup, setCloseWarningPopup] = useState(false)
	const [newCardAdded, setNewCardAdded] = useState(false)
	const [runTimer, setRunTimer] = useState(false)
	const [runningSeconds, setRunningSeconds] = useState(0)
	const [customerHaveCard, setCustomerHaveCard] = useState(true)
	const [isScreenShared, setIsScreenShared] = useState(false)
	const [intialTimeVal, setIntialTimeVal] = useState(0)
	const [enableStartPauseTimerButton, setEnableStartPauseTimerButton] = useState(false)
	const timerRef = useRef()
	const warningTimerRef = useRef()
	const lastMinuteTimerRef = useRef()
	const freeMinuteExpireTimerRef = useRef()
	const [showEndmeetingButton, setShowEndmeetingButton] = useState(true)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [approveClicked, setApproveClicked] = useState(false)
	const [showTransferButton, setShowTransferButton] = useState(true)
	const [liveUser, setliveUser] = useState(null)
	const [microPhoneStatusCustomer, setMicroponeStatusCustomer] = useState(false)
	const [microPhoneStatusTechnician, setMicroponeStatusTechnician] = useState(false)
	// This state check if customer have subscription or not  even when sub minutes are finished
	const [haveSubscription, setHaveSubscription] = useState(false)
	// This state check if customer have subscription min or not
	const [haveSubMin, setHaveSubMin] = useState(false)
	const [startTimerForMeeting, setStartTimerForMeeting] = useState(Date.now())
	const [micPermission, setMicPermission] = useState('notSet')
	const [showMicPermissionModal, setShowMicPermissionModal] = useState(false)
	const [showNotificationDot, setNotificationDot] = useState(false)
	const [expandableOpen, setExpandaleOpen] = useState(true)
	const [socketHits, setSocketHits] = useState(0)
	const isiPad = useMediaQuery('(max-width: 1024px)')
	const isLaptopLandscape = useMediaQuery('(min-width: 1025px) and (orientation: landscape)')

	const [showRemoteAccessModal, setShowRemoteAccessModal] = useState(false)
	const [browserData, setBrowserData] = useState({
		isSafariBrowser: false,
		safariMessage: '',
	})

	const [internetConnectionStatus, setInternetConnectionStatus] = useState({ customer: '', technician: '' })
	const [requiredParticipantJoined, setRequiredParticipantJoined] = useState(false)
	const [startChat, setStartChat] = useState('not-Set')
	const [phoneStatusAvailability, setPhoneStatusAvailability] = useState(false)

	const [fiveMinModalAction, setFiveMinModalAction] = useState({
		customerMeetConfirmModal: false,
		switchGeekModal: false,
		troubleShootModal: false,
	})
	const [LeaveTheMeeting, setLeaveTheMeeting] = useState(false)
	const [showEndMeetingSpinner, setShowEndMeetingSpinner] = useState(false)
	const [showUpdateJobModal, setShowUpdateJobModal] = useState(false)
	const [showTransfterCallLoader, setShowTransfterCallLoader] = useState(false)
	const [dataLoaded, setDataLoaded] = useState(false)
	const [open, setOpen] = React.useState(false)
	const { openBackDrop, setOpenBackDrop, startPaidTimerCompIsLoaded, handleBackDropChange, isBackDropOpen, setIsLoading, setLoader } =
		useJitsiMeet()
	const [transferWithoutSubmit, setTransferWithoutSubmit] = useState(false)
	const [startThirtySeconds, setStartThirtySeconds] = useState(false)
	const [countdown, setCountdown] = useState(30)
	const [startTimerThroughSocket, setStartTimerThroughSocket] = useState(false)
	const [countdownTroubleShootModal, setCountdownTroubleShootModal] = useState(30)
	const [startTroubleShootModal, setStartTroubleShootModal] = useState(false)
	const [continueMeeting, setContinueMeeting] = useState(false)
	const [continueMeetingThroughSocket, setContinueMeetingThroughSocket] = useState(false)
	const [startswitchGeekModalModal, setStartswitchGeekModalModal] = useState(false)
	const [disableTransferCancelBtn, setDisableTransferCancelBtn] = useState(false)
	const [hasMeetingStarted, setHasMeetingStarted] = useState(false)
	const [meetingStartedByCustomer, setMeetingStartedByCustomer] = useState(false)
	const initialTransferAction = {
		jobId: '',
		transferCall: false,
	}
	const [customerResponceBackForTransfer, setCustomerResponceBackForTransfer] = useState(() => {
		const storedData = localStorage.getItem('customerResponceBackForTransfer')
		return storedData ? JSON.parse(storedData) : initialTransferAction
	})

	const initialCustomerModalAction = {
		jobId: '',
		customerMeetConfirmModal: false,
		troubleShootModal: false,
		switchGeekModal: false,
	}
	const [openNotifinationForTech, setOpenNotifinationForTech] = useState(false)

	const [customerModalAction, setCustomerModalAction] = useState(() => {
		const storedData = localStorage.getItem('customerModalAction')
		return storedData ? JSON.parse(storedData) : initialCustomerModalAction
	})
	const [uploadFiles, setUploadFiles] = useState(false)
	const [files, setFiles] = useState([])

	const [showRemoteAccessModalZoho, setShowRemoteAccessModalZoho] = useState(false)
	const [zohoUrl, setZohoUrl] = useState('')
	const [sessionData, setSessionData] = useState(null)
	const [socketData, setSocketData] = useState(null)
	const [isRemoteLoading, setIsRemoteLoading] = useState(false)
	const [showWelcomePartnerPopup, setShowWelcomePartnerPopup] = useState(false)
	const [partnerData, setPartnerData] = useState('')
	const [showScreenConnectIframe, setShowScreenConnectIframe] = useState(false)
	const [showOneHourModal, setShowOneHourModal] = useState(false)
	const [oneHourCompleted, setOneHourCompleted] = useState(false)
	const [oneHourPass, setOneHourPass] = useState(false)
	const [showWarningApproachingTheEstimatedTime, setShowWarningApproachingTheEstimatedTime] = useState(false)
	const [showAutoSwitchPopup, setShowAutoSwitchPopup] = useState(false)
	// useEffect(() => {
	// console.log('startTimerThroughSocket :: :: ::',startTimerThroughSocket)
	// }, [startTimerThroughSocket])

	const handleInputChange = (key, value) => {
		setCustomerModalAction((prevFormData) => ({
			...prevFormData,
			[key]: value,
		}))
	}

	const openFiftyFiveMinuteModal = () => {
		if (job?.isPartnerFirstJob) {
			setShowOneHourModal(true)
		}
	}
	const triggerOneHourFunction = () => {
		if (job?.isPartnerFirstJob) {
			setOneHourCompleted(true)
		} else {
			console.log('Not a partner first job')
			autoSwithTo1Hour()
		}
	}
	useEffect(() => {
		// Function to end the meeting
		const endMeetingIfOverOneHour = () => {
			if (job?.isPartnerFirstJob) {
				if (runningSeconds > 3600) {
					// 3600 seconds = 1 hour
					setOneHourPass(true)
				}
			}
		}

		// Call the function whenever runningSeconds updates
		endMeetingIfOverOneHour()
	}, [runningSeconds])

	useEffect(() => {
		try {
			const { switchGeekModal, troubleShootModal, jobId } = customerModalAction

			const formDataString = JSON.stringify(customerModalAction)
			localStorage.setItem('customerModalAction', formDataString)

			if (startPaidTimerCompIsLoaded && jobId === job.id) {
				if (switchGeekModal) {
					setModalAction('switchGeekModal')
					setStartswitchGeekModalModal(true)
				} else if (troubleShootModal) {
					setModalAction('troubleShootModal')
					setStartTroubleShootModal(true)
				}
			}
		} catch (error) {
			console.error('what setting localStorage for modals useEffect:', error)
		}
	}, [customerModalAction, startPaidTimerCompIsLoaded, job])

	useEffect(() => {
		const fetchData = async () => {
			try {
				let response
				let jobIds = [jobId]
				if (job && job.is_transferred && job.transfer_reference_job) {
					jobIds.push(job.transfer_reference_job)
				}
				response = await JobFileApi.listOfUploadFile({ jobIds: jobIds }, user.id)
				setFiles(response?.files)
			} catch (error) {
				console.error('Error fetching files:', error)
			}
		}

		fetchData()
		if (socket) {
			socket.on('refresh-upload-file', async (data) => {
				if (data.userType !== user?.userType) {
					fetchData()
				}
			})
		}
	}, [job, socket, uploadFiles])

	useEffect(() => {
		try {
			const { jobId, transferCall } = customerResponceBackForTransfer

			if (jobId === job?.id && transferCall && startPaidTimerCompIsLoaded) {
				setShowUpdateJobModal(true)
				setDisableTransferCancelBtn(true)
			}

			if (job?.meetingStarted) {
				handleBackDropChange('jobId', '')
				handleBackDropChange('isBackdropVisible', false)
				// if (userType === 'technician' && continueMeeting) {
				// openNotificationWithIcon('success', 'Meeting is resumed, billing starts now.');
				// }
			}
			let shouldStartPaidTimer = startPaidTimerCompIsLoaded && !job?.customer_start_meeting_action
			console.log('inside the modal shouldStartPaidTimer case')

			if (shouldStartPaidTimer) {
				let techSelectedOption = job?.tech_solved_issue
				handleInputChange('jobId', job?.id)
				console.log('inside the modal techSelectedOption case')
				switch (techSelectedOption) {
					case 'Yes':
						// console.log('inside the modal Yes case')
						setModalAction('customerMeetConfirmModal')
						handleInputChange('customerMeetConfirmModal', true)
						setContinueMeetingThroughSocket(true)
						setContinueMeeting(true)
						break

					case 'mayBe':
						// console.log('inside the modal mayby case')
						setModalAction('troubleShootModal')
						handleInputChange('troubleShootModal', true)
						setStartTroubleShootModal(true)
						setStartTimerThroughSocket(true)
						break

					case 'No':
						// console.log('inside the modal no case')
						setModalAction('switchGeekModal')
						handleInputChange('switchGeekModal', true)
						setStartTimerThroughSocket(true)
						setStartswitchGeekModalModal(true)
						break

					default:
						break
				}
			}
		} catch (error) {
			console.error('what setting modals from dataBase useEffect:', error)
		}
	}, [customerResponceBackForTransfer, job, startPaidTimerCompIsLoaded, continueMeeting])

	useEffect(() => {
		if (job && user && userType === 'technician') {
			let estimatedTimeInMinutes = job.estimatedTime ? parseInt(job.estimatedTime.split('-')[1]) : 0
			// Convert estimated minutes to milliseconds for half time
			const halfEstimatedTimeMs = (estimatedTimeInMinutes * 60 * 1000) / 2

			// Get current timer value if available
			if (timerRef.current && timerRef.current.getTime()) {
				const currentTimeMs = timerRef.current.getTime()

				// console.log("🚀 ~ Current time (ms):", currentTimeMs);
				// console.log("🚀 ~ Half estimated time (ms):", halfEstimatedTimeMs);

				// Show warning when we've reached half the estimated time
				if (currentTimeMs >= halfEstimatedTimeMs && !showWarningApproachingTheEstimatedTime) {
					// console.log("🚀 ~ Half time reached!");
					const isDismissed = localStorage.getItem('technicianDismissedApproachingEstimatedTime') === 'true';
					if (!isDismissed) {
						setShowWarningApproachingTheEstimatedTime(true)
					}
				}
			}
		}
	}, [job, user, userType, timerRef?.current?.getTime(), runningSeconds])

	const handleOk = () => {
		setShowOneHourModal(false)
	}

	const handleCancel = () => {
		setShowOneHourModal(false)
	}
	const startBilledMeeting = async (automaticallyStarted = false) => {
		try {
			const jobInfoResponse = await getJobInformation(jobId, user?.id)
			const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
			if (!jobInfo.meetingStarted) {
				console.log('inside startBilledMeeting')
				startTimer(jobInfo, false, socket, totalPaidJobs)
				let data = { meetingStatus: 'meetingHasStarted', jobId: jobInfo.id }
				socket.emit('meeting-started-update', data)
				let dataToUpdate = automaticallyStarted ? 'automatically Start Billed Meeting after 30 seconds' : 'Start Billed Meeting'
				updateCustomerStartMeetingResponse(jobInfo.id, dataToUpdate, user.id)
				setShowUpdateJobModal(false)
				setStartTroubleShootModal(false)
				setCustomerModalAction(initialCustomerModalAction)
			}
			setModalAction('resetAll')
		} catch (error) {
			console.error('Error in startBilledMeeting:', error)
		}
	}

	useEffect(() => {
		if (userType === 'technician') {
			const hasTechSolvedIssue = job?.tech_solved_issue === 'mayBe' || job?.tech_solved_issue === 'Yes'
			const isMeetingActionMissing = !job?.customer_start_meeting_action

			if (hasTechSolvedIssue && isMeetingActionMissing) {
				startBilledMeetingByTech()
			}
		}
	}, [job, user, socket])

	const startBilledMeetingByTech = async () => {
		try {
			if (userType === 'technician') {
				const jobInfoResponse = await getJobInformation(job.id, user?.id)
				const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
				console.log(jobInfo, 'job info :: :: :: ::')
				if (!jobInfo.meetingStarted) {
					console.log('inside the startBilledMeetingByTech')
					startTimer(jobInfo, false, socket, totalPaidJobs)
					setShowUpdateJobModal(false)
					setOpenBackDrop(false)
					setCustomerModalAction(initialCustomerModalAction)
					setRequiredParticipantJoined(false)
					setHasMeetingStarted(true)
					setMeetingStartedByCustomer(true)
					let dataToUpdate = 'Start Billed Meeting'
					updateCustomerStartMeetingResponse(jobInfo.id, dataToUpdate, user.id)
					// openNotificationWithIcon('success', 'Meeting is resumed, billing starts now.');
				}
			}
		} catch (error) {
			console.error('Error in startBilledMeeting:', error)
		}
	}

	const transferAfterThirtySec = () => {
		try {
			const { isBackdropVisible, jobId } = isBackDropOpen

			if (isBackdropVisible && jobId === job.id) {
				return
			}

			socket.emit('cus-response-for-modalAction', { transferCall: true, jobId: job.id })
			setModalAction('resetAll')
			updateCustomerStartMeetingResponse(job.id, 'Transfer', user.id)
			setCustomerModalAction(initialCustomerModalAction)
			handleBackDropChange('jobId', job.id)
			handleBackDropChange('isBackdropVisible', true)
		} catch (error) {
			console.error('Error in transferAfterThirtySec:', error)
		}
	}

	useEffect(() => {
		try {
			if (startswitchGeekModalModal && userType === 'customer') {
				const { isBackdropVisible, jobId } = isBackDropOpen
				const timeoutId = setTimeout(() => {
					if (isBackdropVisible && jobId === job.id) {
						return
					}
					transferAfterThirtySec()
					// handleInputChange('switchGeekModal', false)
					// setModalAction('resetAll')
				}, 30000)
				return () => clearTimeout(timeoutId)
			}
		} catch (error) {
			console.error('Error in startswitchGeekModalModal useEffect:', error)
		}
	}, [startswitchGeekModalModal, isBackDropOpen])

	useEffect(() => {
		try {
			if (startTroubleShootModal && userType === 'customer') {
				const timeoutId = setTimeout(() => {
					startBilledMeeting(true)
					// handleInputChange('troubleShootModal', false)
					// handleInputChange('jobId', '')
					// setModalAction('resetAll')
				}, 30000)

				return () => {
					clearTimeout(timeoutId)
				}
			}
		} catch (error) {
			console.error('Error in startTroubleShootModal useEffect:', error)
		}
	}, [startTroubleShootModal])

	useEffect(() => {
		try {
			if (continueMeeting && userType === 'customer') {
				const timeoutId = setTimeout(() => {
					startBilledMeeting(true)
					// handleInputChange('customerMeetConfirmModal', false)
					// handleInputChange('jobId', '')
					// setModalAction('resetAll')
				}, 30000)

				return () => {
					clearTimeout(timeoutId)
				}
			}
		} catch (error) {
			console.error('Error in continueMeeting useEffect:', error)
		}
	}, [continueMeeting])

	useEffect(() => {
		try {
			if (startTimerThroughSocket && userType === 'customer') {
				const intervalId = setInterval(() => {
					// setCountdown((prevCountdown) => prevCountdown - 1)
					setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 0))
				}, 1000)

				return () => {
					clearInterval(intervalId)
				}
			}
		} catch (error) {
			console.error('Error in continueMeeting startTimerThroughSocket:', error)
		}
	}, [startTimerThroughSocket])

	const [tetchTokenFoundFromCookie, setTetchTokenFoundFromCookie] = useState(false)

	// This is imported in order to get JobId if there is not userId available in cookie
	const location = useLocation()
	/**
	 * @description : This useEffect is used to get user_id from cookie and from router details if user_id is not available in Cookie
	 * @author : Jagroop
	 * Note  : Below console log will be removed when issue of user_id is resolved : 21-march-2023
	 */
	useEffect(() => {
		let userId = getCookie('user_id')
		let tokenFromCookie = getCookie('user_access')
		if (userId) {
			// console.log('Setting user id from cookie', userId)
			setUserId(userId)
		} else {
			if (userType) {
				// console.log('Inside If conditions called to get user_id', userType)
				getUserId()
			}
		}
		if (tokenFromCookie) {
			setTetchTokenFoundFromCookie(false)
			localStorage.setItem(SECRET_KEY, tokenFromCookie)
			console.log(
				'Looking for tokenFromCookie - found',
				// {token : "..."+tokenFromCookie.substring(tokenFromCookie.length - 10)}
			)
		} else {
			setTetchTokenFoundFromCookie(true)
			console.log('Looking for tokenFromCookie - not found')
		}
		checkMicrophoneStatus()
	}, [])

	/**
	 * @description : this function is used to show Popup to Technician that customer is joined from Safari Browser
	 * @author : Nafees
	 */
	const detectCustomerBrowser = async () => {
		try {
			const getBrowser = window.sessionStorage.getItem('safariBrowser')
			if (userTypeParams == 'customer' && socket && user && jobId && getBrowser == null) {
				let userAgent = navigator.userAgent
				let browserInfo = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
				let browserName = browserInfo[1]
				let customerName = user?.firstName ? user?.firstName : 'Customer'
				console.log('Customer Browser', { browserName, jobId, customerName })
				if (browserName === 'Safari') {
					const data = {}
					data['SafariBrowser'] = true
					data['jobId'] = jobId
					data['customerName'] = customerName
					window.sessionStorage.setItem('safariBrowser', jobId)
					socket.emit('Set-Detect-browser', data)
				}
			}
		} catch (error) {
			console.log("error while showing : customer's browser is safari", error)
		}
	}

	/**
	 * @description : this function is used to show Popup of Microphone error
	 * @author : Jagroop
	 */
	const checkMicrophoneStatus = async () => {
		const permissionState = await checkMediaPermission()
		console.log('Permission State Check in Meeting', permissionState)
		setMicPermission(permissionState)
		if (permissionState != 'permission-given') {
			setShowMicPermissionModal(true)
		}
	}

	useEffect(() => {
		getJobDetails()
	}, [user])

	const getJobDetails = async () => {
		try {
			const JobId = location?.pathname.split('/')
			const getJobDetail = await MeetingApi.getJobInformation(JobId[JobId.length - 1], user?.id)
			await checkSubscriptionAvailability(getJobDetail)
		} catch (error) {
			console.log('Error in Getting Job Details', error)
		}
	}

	// This function will check if customer or it's owner have subscription or not
	const checkSubscriptionAvailability = async (job) => {
		// console.log('My console to look for 2', job)
		if (job) {
			//Here checking customer have subscion or have owner id
			const haveSubscription = job?.data?.customer?.subscription && job?.data?.customer.subscription?.invoice_id
			// This condition will check if customer have subscription even when subscription minutes are utilized
			if (haveSubscription) {
				setHaveSubscription(true)
			}
			const usedAllSubScriptionTime = job?.data?.customer?.subscription?.time_used == job?.data?.customer?.subscription?.total_seconds
			const haveOwnerId = job?.data?.customer?.user?.ownerId
			// This condition will check customer have subsciption and have subscription minutes
			if (haveSubscription && !usedAllSubScriptionTime) {
				setHaveSubMin(true)
			}
			// If owner id is available then extract details of that owner and check owner have subscription available
			if (haveOwnerId) {
				; (async () => {
					const ownerCustomerResponse = await CustomerApi.getCustomer(
						haveOwnerId,
						job?.data?.customer?.user?.id ? job?.data?.customer?.user?.id : job?.data?.customer?.user,
					)
					const isOwnerHaveSubscription = ownerCustomerResponse?.subscription && ownerCustomerResponse?.subscription?.invoice_id
					// This condition will check if customer's owner have subscription even when subscription minutes are utilized
					if (haveSubscription) {
						setHaveSubscription(true)
					}
					const usedAllSubScriptionTime =
						ownerCustomerResponse?.subscription?.time_used == ownerCustomerResponse?.subscription?.total_seconds
					// This condition will check customer's owner have subsciption and have subscription minutes
					if (isOwnerHaveSubscription && !usedAllSubScriptionTime) {
						setHaveSubMin(true)
					}
				})()
			}
		}
	}

	const getUserId = async () => {
		try {
			const JobId = location?.pathname.split('/')
			const getJobDetail = await MeetingApi.getJobInformation(JobId[JobId.length - 1], user?.id)
			// console.log('Setting user id from router details', { getJobDetail })
			let userId = ''
			if (userType === 'customer') {
				userId = getJobDetail?.data?.customer?.user?.id
			} else {
				userId = getJobDetail?.data?.technician?.user?.id
			}

			if (userId && userId != '') {
				setUserId(userId)
				setCookie(userId)
			}
			// mixpanel code
			mixpanel.identify(userType)
			mixpanel.track(userType + 'check Job detail when Setting user id from router details is called ', { getJobDetail, userId })
			// mixpanel code
		} catch (error) {
			console.log('Error in getting user id from cookie and router details', error)
		}
	}
	// const handleZohoSessionClick = (userType) => {
	// 	if (userType === 'customer') {
	// 		sessionData.userType = userType
	// 		setShowRemoteAccessModalZoho(false)
	// 		// socket.emit('zoho-session', sessionData);
	// 	}
	// 	if (userType === 'technician') {
	// 		setShowRemoteAccessModalZoho(false)
	// 		socketData.userType = userType
	// 		// socket.emit('technician-want-tojoin',socketData);
	// 	}
	// }

	// const openModal = () => {
	// 	setShowRemoteAccessModalZoho(true)
	// }

	// const closeModal = () => {
	// 	setShowRemoteAccessModalZoho(false)
	// }
	useEffect(() => {
		if (socket) {
			/**
			 * Getting response of technician's response about if he can help customer or not or troubleshoot
			 * @params = socketData (Type:Object)
			 * @response : Opens up relative modal
			 * @author : Kartar Singh
			 */
			socket.on('tech-response-back-for-helping-customer', async (data) => {
				try {
					if (job.id == data.jobId && user.userType === 'customer') {
						// console.log('Looking for response from tech', data)
						let TechSelectedOption = data?.selectedOpt
						handleInputChange('jobId', job.id)
						switch (TechSelectedOption) {
							case 'Yes':
								setModalAction('customerMeetConfirmModal')
								handleInputChange('customerMeetConfirmModal', true)
								setContinueMeetingThroughSocket(true)
								setContinueMeeting(true)
								break
							case 'mayBe':
								setModalAction('troubleShootModal')
								handleInputChange('troubleShootModal', true)
								setStartTroubleShootModal(true)
								setStartTimerThroughSocket(true)
								break
							case 'No':
								setModalAction('switchGeekModal')
								handleInputChange('switchGeekModal', true)
								setStartTimerThroughSocket(true)
								setStartswitchGeekModalModal(true)
								break
							default:
								break
						}
					}
				} catch (error) {
					console.error('Error in tech-response-back-for-helping-customer:', error)
				}
			})

			socket.on('meeting-started-update-tech', async (data) => {
				if (job.id === data.jobId && userType === 'technician') {
					setHasMeetingStarted(true)
					setOpenBackDrop(false)
					setMeetingStartedByCustomer(true)
					setRequiredParticipantJoined(false)
				}
			})

			/**
			 * Getting response of custoemr's response about if he trnasfer the job than open the transfer modal
			 * @params = socketData (Type:Object)
			 * @response : Opens up relative modal
			 * @author : Kartar Singh
			 */
			socket.on('tech-response-back-for-modalAction', async (data) => {
				try {
					if (job.id == data.jobId && userType === 'technician') {
						if (data.transferCall) {
							setShowUpdateJobModal(true)
							handleBackDropChange('jobId', job.id)
							handleBackDropChange('isBackdropVisible', false)
							setDisableTransferCancelBtn(true)
							let dataToSend = { jobId: job.id, transferCall: true }
							const formDataString = JSON.stringify(dataToSend)
							localStorage.setItem('customerResponceBackForTransfer', formDataString)
						}
					}
				} catch (error) {
					console.error('Error in tech-response-back-for-modalAction:', error)
				}
			})

			/**
			 * Start time ticking and check warning popup show or not
			 * @params = socketData (Type:Object)
			 * @response : Start time ticking and check warning popup show or not
			 * @author : Karan
			 */
			socket.on('Timer:ticking', async (socketData) => {
				if (socketData) {
					const meetingSeconds = socketData['seconds']
					if (meetingSeconds === 1) {
						//const jobInfo = { ...job }
						const jobInfoResponse = await getJobInformation(jobId, user?.id)
						const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
						jobInfo['meeting_start_time'] = socketData['meetingStartTime']
						jobInfo['customerConfirmedNotes'] = true
						clearTimeOutAndIntervals('all')
						setJob(jobInfo)
						let haveCard = await checkCustomerHaveCard(jobInfo.customer.id, userId, liveUser)
						if (jobInfo.isPartnerFirstJob) {
							haveCard = true
						}
						let lifeCycleTag = ''
						if (jobInfo && jobInfo.is_transferred && jobInfo.is_transferred == true) {
							if (userType && userType !== '') {
								lifeCycleTag =
									userType === 'technician'
										? JobTags.PAID_TIME_START_AFTER_TRANSFER_FROM_TECH
										: JobTags.PAID_TIME_START_AFTER_TRANSFER_FROM_CUST
							}
						} else {
							if (userType && userType !== '') {
								lifeCycleTag = userType === 'technician' ? JobTags.PAID_TIME_START_FROM_TECH : JobTags.PAID_TIME_START_FROM_CUST
							}
						}
						await JobCycleApi.create(lifeCycleTag, job.id, false)
						startMeetingTimer(jobInfo, haveCard)
						setEnableStartPauseTimerButton(true)
						if (userType == 'customer') {
							setRequiredParticipantJoined(false)
							notification.destroy()
							notification.success({ key: 'billingStarted', duration: 4, message: 'Meeting timer started.' })
						}
					}
					// console.log('running seconds in timer ticking :::::::::', meetingSeconds)
					setRunningSeconds(meetingSeconds)
					detectCustomerBrowser()
				}
			})

			/**
			 * Pause browser timer
			 * @params = socketData (Type:Object)
			 * @response : Pause browser timer and update job info
			 * @author : Karan
			 */
			socket.on('Timer:pause', async (socketData) => {
				const jobInfoResponse = await getJobInformation(jobId, user?.id)
				const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
				timerRef.current.pause()
				setJob(jobInfo)
				let haveCard = await checkCustomerHaveCard(jobInfo.customer.id, userId, liveUser)
				if (jobInfo.isPartnerFirstJob) {
					haveCard = true
				}
				startMeetingTimer(jobInfo, haveCard)
			})
			// This will play notification sound to technician when customer send message
			socket.on('open-chat-panel-talkjs', (data) => {
				if (userType == 'technician') {
					// play()
				}
			})

			// This will play notification sound to customer when technician send message
			socket.on('open-chat-panel-talkjs-for-customer', (data) => {
				if (userType == 'customer') {
					// play()
				}
			})
			/**
			 * Resume browser timer
			 * @params = socketData (Type:Object)
			 * @response : Resume browser timer and update job info
			 * @author : Karan
			 */
			socket.on('Timer:resume', async (socketData) => {
				const jobInfoResponse = await getJobInformation(jobId, user?.id)
				const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
				setJob(jobInfo)
				let haveCard = await checkCustomerHaveCard(jobInfo.customer.id, userId, liveUser)
				if (jobInfo.isPartnerFirstJob) {
					haveCard = true
				}
				startMeetingTimer(jobInfo, haveCard)
			})

			/**
			 * Update job details for customer
			 * @params = socketData (Type:Object)
			 * @response : Will update job details when technician will update it
			 * @author : Karan
			 */
			socket.on('Update:job-details', async (socketData) => {
				const jobInfoResponse = await getJobInformation(jobId, user?.id)
				const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
				setJob(jobInfo)
			})

			/**
			 * Open Zoho page for technician
			 * @params = socketData (Type:Object)
			 * @response : Will open zoho page for technician
			 * @author : Karan
			 */
			socket.on('Open:zoho-page', (socketData) => {
				console.log('receiving socket multiple timer :::', socketData)

				if (userType && userType === 'technician') {
					if (!technicianSession) {
						console.log('working >>>>>>>>>>', technicianSession)
						let zohoTechURL = socketData['representation']['technician_url']
						if (socketData && socketData['representation'] && socketData['representation']['session_id']) {
							zohoTechURL = 'https://assistlab.zoho.com/technicianJoin/' + socketData['representation']['session_id']
						}

						let newWind = window.open(zohoTechURL, '_blank')
						if (!newWind || newWind.closed || typeof newWind.closed == 'undefined') {
							setShowRemoteAccessModal(true)
						}
						setLoader(false)
						technicianSession = true
					}
					setTimeout(() => {
						technicianSession = false
					}, [4000])
				}
			})

			/**
			 * this socket should work only on customer side
			 * @params = no params
			 * @response : will unmute customer if it is mute, or reload page if call start type is phone.
			 * @author : Manibha
			 */
			socket.on('Unmute:Customer-meeting', () => {
				if (userType && userType === 'customer' && jitsiApi != null) {
					if (job.callStartType === 'Phone') {
						window.sessionStorage.setItem('unMuteCusomter', 'yes')
						window.location.reload()
					} else {
						jitsiApi.isAudioMuted().then((muted) => {
							if (muted) {
								// here reload is done to configure jitsi again as just toggling audio does not work when customer wants to hear technician
								window.location.reload()
							}
						})
					}
				}
			})
			// participant-left:notify
			socket.on('participant-left:notify', (data) => {
				console.log('participant-left:notify', data)
				if (userType == data?.userType && data?.showPopup && job?.meeting_start_time) {
					notification.destroy()
					notification.warn({ key: 'videoConferenceLeft', duration: 4, message: 'Connection lost' })
					Modal.destroyAll()
					Modal.warn({
						title: 'Oops! The meeting got disconnected.\n Don’t worry, Geeker is calling your phone number to reconnect.',
						footer: null,
						centered: true,
						okButtonProps: { style: {} },
						maskClosable: false,
						destroyOnClose: true,
						closable: true,
					})
				}
			})

			socket.on('reconnection-alert:notify', (data) => {
				console.log('reconnection-alert:notify', data)
				if (userType != data?.userType) {
					notification.destroy()
					notification.success({ key: 'reconnection-alert', duration: 4, message: "You've been reconnected! Enjoy your meeting" })
				}
			})

			/**
			 * this socket should work only on customer side
			 * @params = no params
			 * @response : will hit zoho at customer's side
			 * @author : Manibha
			 */
			socket.on('Enable:Customer-zoho', (data) => {
				console.log('>>>>>>>>>>receiving Enable:Customer-zoho >>> for 2 times')
				if (userType && userType === 'customer') {
					console.log('hit user type button :::: ')
					hitZohoRemoteSession(user.email, 'Technician', socket, job, data['userClicks'])
				}
			})

			socket.on('call-to-customer', () => {
				if (userType === 'customer' && notifyCustomer && job && jitsiApi) {
					// console.log('user12::::', user)
					notifyCustomer = false
					muteMicrophone(jitsiApi)
					openNotificationWithIcon('info', 'Info', `Your Geek is calling you by phone: ${job.customer.phoneNumber}.`)
					setTimeout(() => {
						notifyCustomer = true
					}, [[10000]])
				}
			})
			socket.on('Show-browser-msg', (data) => {
				// console.log('show data comes form backedn', data, userType)
				if (userType === 'technician' && data.SafariBrowser) {
					setBrowserData({
						isSafariBrowser: true,
						safariMessage: `Attention: ${data.customerName} is using Safari browser and may encounter screen sharing difficulties.`,
					})
				}
			})

			socket.on('meeting-started-to-tech', (data) => {
				// console.log('show data comes form backedn', data)
				if (job.id === data.jobId) {
					setOpenBackDrop(false)
					setOpenNotifinationForTech(true)
				}
			})
		}
	}, [socket, jitsiApi])

	useEffect(() => {
		if (userType === 'technician' && openNotifinationForTech) {
			openNotificationWithIcon('success', 'Meeting timer started.')
		}
	}, [openNotifinationForTech])

	const setModalAction = (field) => {
		setFiveMinModalAction((prev) => ({
			customerMeetConfirmModal: field === 'customerMeetConfirmModal',
			switchGeekModal: field === 'switchGeekModal',
			troubleShootModal: field === 'troubleShootModal',
		}))
	}

	useEffect(() => {
		if (job && job.meetingStarted && !job.meeting_pause) {
			console.log('My runningSeconds are ..', runningSeconds)
			openSixMinuteModal()
		}
	}, [runningSeconds, runTimer])

	// this is commented because we are not using payment hold functionality right now
	// useEffect(() => {
	// 	let softwareRate = job?.software?.rate
	// 	let addedMinutes = getCalculateCycle(softwareRate)
	// 	setPaymentHoldTime(addedMinutes)
	// 	console.log('check addedMinutes', addedMinutes)
	// }, [job])

	/**
	 * this function will calculate software reate cycle which is used to detect when meeting reach $100
	 * @params = softwareRate
	 * @response : return miliseconds converted from minutes.
	 * @author : Nafees
	 */
	// function getCalculateCycle(rate) {
	// 	let amount = 100
	// 	let cycleCount = Math.ceil(amount / rate)
	// 	let minutes = cycleCount * 6
	// 	let minIntoMiliSeconds = minutes * 60000
	// 	return minIntoMiliSeconds
	// }

	/**
	 * Add card modal handler
	 * @params = ''
	 * @response : Will open the add card modal and stop the screenshare
	 * @author : Karan
	 */
	const openAddCardModal = (is_approve_long_job) => {
		if (is_approve_long_job == false) {
			setApproveClicked(false)
		}
		// console.log('model will try to open from this but now it is commented here')
		// setIsModalOpen(true)
		if (jitsiApi && isScreenShared) {
			jitsiApi.executeCommand('toggleShareScreen')
		}
		if (!job.meeting_pause) {
			const data = {}
			data['jobId'] = job.id
			data['userType'] = user.userType
			socket.emit('Pause:timer', data)
		}
	}

	/**
	 * Update the start call time if not already in database
	 * @params = jobInfo (Type:Object)
	 * @response : no response
	 * @author : Manibha
	 */
	const setStartCallTime = (jobInfo) => {
		if (jobInfo.start_call_time == undefined) {
			const dataToUpdate = {
				start_call_time: new Date(),
			}
			updateJob(jobId, {
				dataToUpdate: dataToUpdate,
				cuId: user?.id,
			})
		}
	}

	useEffect(() => {
		if (userId != null) {
			fetchUser(userId)
		} else {
			getUserId()
		}
		// If there is JobId then we will set Cookie and if cookie is already set then we don't reset it again
		const JobIdExtractor = location?.pathname.split('/')
		const JobId = JobIdExtractor[JobIdExtractor.length - 1]
		if (JobId) {
			let meetingRetires = window.localStorage.getItem(`${JobId}-meeting-retires`)
			// console.log('meetingRetires', { JobId, meetingRetires })
			if (meetingRetires == null) {
				// console.log('Setting meetingRetires from cookie', meetingRetires)
				try {
					window.localStorage.setItem(`${JobId}-meeting-retires`, 0)
				} catch (error) {
					console.log('meetingRetires in catch ', error)
				}
			}
		}
	}, [userId, userType])

	useEffect(() => {
		// console.log('in use effect running seconds>>>>>>>>', intialTimeVal)
		// console.log('runTImer >>>>>>>>>>>> ', runTimer)
		if (runTimer) {
			// console.log('inside runTImer >>>>>>>>>>>>>>>>>>>>>>>>>>>>>', runTimer)
			timerRef.current.start()
		}
	}, [runTimer, intialTimeVal])

	useEffect(() => {
		/**
		 * Update states when new card added
		 */
		if (newCardAdded) {
			updateStatesOnAddCard()
		}
	}, [newCardAdded])

	const updateStatesOnAddCard = () => {
		setCustomerHaveCard(true)
		setShowFreeMinutesEndPopup(false)
		setShowLastMinuteWarningPopup(false)
		setCloseWarningPopup(true)
	}

	useEffect(() => {
		; (async () => {
			if (user) {
				setliveUser(isLiveUser(user))
				// console.log('userType is ', userType)
				// console.log('user details are ', user)

				/**
				 * Call logrocket to save session
				 */
				LogRocket.identify(user.id, {
					name: user.firstName,
					email: user.email,
				})

				/**
				 * Call fullstory to save session
				 */
				FullStory.identify(user.id, {
					displayName: user.firstName,
					email: user.email,
				})

				/**
				 * Get job information
				 * @params = jobId (Available in url)
				 * @response : Return all the job information
				 * @author : Karan
				 */

				const jobInfoResponse = await getJobInformation(jobId, user?.id)
				const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
				// console.log('jobInfo', jobInfo)
				if (jobInfo.id) {
					if (jobInfo.status === JOB_STATUS.COMPLETED) {
						localStorage.removeItem('coBrandingLogoUrl')
						// window.location.href= `/meeting-over/${userType}/${jobInfo.id}`
						window.location.href = `${MAIN_APP_URL}/meeting-feedback/${jobInfo.id}`
					} else {
						setJob(jobInfo)
						const isCustomer = checkIsCustomer(userType)

						if (isCustomer) {
							setCustomerInfo(jobInfo.customer)
						}

						let haveCard = customerHaveCard
						haveCard = await checkCustomerHaveCard(jobInfo.customer.id, user?.id, liveUser)
						if (jobInfo.isPartnerFirstJob) {
							haveCard = true
						}
						setCustomerHaveCard(haveCard)

						if (jobInfo.meetingStarted) {
							setEnableStartPauseTimerButton(true)
						}

						const totalPaidJobsResponse = await getTotalPaidJobs(jobInfo.id, jobInfo.customer.id, user?.id)
						console.log('totalPaidJobsResponse.>>>>', totalPaidJobsResponse)
						const paidJobs = totalPaidJobsResponse.success ? totalPaidJobsResponse.data.totalPaidJobs : 0
						setTotalPaidJobs(paidJobs)

						/**
						 * Initialization of socket
						 */
						if (!socket) {
							socketInit(jobInfo, userType)
						}

						/**
						 * Call meeting timer
						 */
						startMeetingTimer(jobInfo, haveCard)

						/**
						 * Fill start call time
						 */
						setStartCallTime(jobInfo)
					}
				} else {
					window.location.href = `/invalid-meeting/${userType}/${jobId}`
				}
			}
		})()
	}, [user])

	useEffect(() => {
		if (user) {
			mixpanel.identify(user?.email)
			if (pageAccessedByReload) {
				if (pageAccessedByReload && userType === 'customer') {
					console.log('>>>PageReload>>>> Customer - Reload the page.')
					mixpanel.track(userType + ' - Reload the page.', { JobId: jobId })
				} else {
					console.log('>>>PageReload>>>>  Technician - Reload the page.')
					mixpanel.track(userType + '- Reload the page.', { JobId: jobId })
				}
			} else {
				console.log('>>>PageReload>>>> Page reload through code for ', user.userType)
				mixpanel.track('Page reload through code.', { JobId: jobId })
			}
		}
	}, [user])

	const pageAccessedByReload =
		(window.performance && window.performance === 1) ||
		window.performance
			.getEntriesByType('navigation')
			.map((nav) => nav.type)
			.includes('reload')

	/**
	 * Fetch user details
	 * @params = userId (Type:string)
	 * @response : Return user details
	 * @author : Karan
	 */
	const fetchUser = async (userId) => {
		// console.log('going to fetch details of ', userId)
		const res = await getUserByParam({ userId: userId, cuId: userId })
		// console.log('User details are :::::', res)
		if (res) {
			DATE_OPTIONS['timeZone'] = res.user.timezone ? res.user.timezone : DATE_OPTIONS['timeZone']
			if (res.userType === 'customer') {
				let customer = await getCustomer(res.user.id, userId)
				res.customer = customer
			}
			let temp = {}
			let keys = Object.keys(res.user)
			for (var index in keys) {
				temp[keys[index]] = res.user[keys[index]]
				if (keys[index] == 'userType') {
					temp[keys[index]] = res.userType
				}
			}

			setTimeout(() => {
				// console.log('temp ::: ', temp)
				setUserType(res.userType)
				setUser(temp)
			}, 500)
		}
	}

	/**
	 * Get socket client
	 * @params = ''
	 * @response : Return socket client
	 * @author : Karan
	 */
	const socketInit = async () => {
		const socketClient = await getSocketClient()
		socketClient.on('connect', function () {
			console.log('Connected to node server :: ', socketClient.connected)
			const data = {}
			data['jobId'] = jobId
			socketClient.emit('Room:connection', data)
			setSocket(socketClient)
		})

		socketClient.on('reconnect_error', (error) => {
			console.log('error in reconnection of socket :::', error)
		})
		socketClient.on('connect_error', (error) => {
			console.log('error in connect_error of socket :::', error)
		})
		socketClient.on('connect_failed', (error) => {
			console.log('error in connect_failed of socket :::', error)
		})
	}
	/**
	 * Play sound for the notification customer side
	 * @params =
	 * @response : Will play a sound when 6 minute notification appears
	 * @author : sahil
	 */

	const playSoundForNotification = () => {
		if (!soundPlayed) {
			play()
			soundPlayed = true
		}
	}

	/**
	 * Check and start the meeting time
	 * @params = jobInfo (Type:Object)
	 * @response : Will check if notes confimed then start the timer
	 * @author : Karan
	 */
	const startMeetingTimer = (jobInfo, haveCard) => {
		timerRef.current.pause()
		if (
			jobInfo.meetingStarted &&
			(jobInfo.status === JOB_STATUS.IN_PROGRESS || (jobInfo.status === JOB_STATUS.LONGJOB && jobInfo.long_job_with_minutes == 'yes'))
		) {
			clearTimeOutAndIntervals('meetingTimer')
			let milliseconds = 0
			const meeting_start_time = new Date(jobInfo.meeting_start_time)
			console.log('meeting_start_time >>>>>>', meeting_start_time, haveCard)
			let now_date = new Date()
			if (jobInfo.meeting_pause) {
				now_date = new Date(jobInfo.pause_start_time)
			}
			// console.log('>>>.now_date >>>>>', now_date)
			let seconds = (now_date.getTime() - meeting_start_time.getTime()) / 1000
			seconds = jobInfo.total_pause_seconds ? seconds - jobInfo.total_pause_seconds : seconds
			// console.log('running seconds in startMeetingTimer :::::::::', seconds)
			// setRunningSeconds(seconds)

			if (jobInfo.total_seconds < seconds) {
				milliseconds = seconds * 1000
				// console.log('inside if >>>>>')
			} else {
				// console.log('inside else >>>>>>>>>>>>>>>>>')
				milliseconds = jobInfo.total_seconds * 1000
			}
			setRunningSeconds(seconds)
			// const milliseconds = seconds * 1000
			// setIntialTimeVal(milliseconds)
			setIntialTimeVal(milliseconds)
			if (!jobInfo.meeting_pause) {
				// console.log('inside meeting pause if ', jobInfo.meeting_pause)
				setRunTimer(true)
				timerRef.current.start()
			} else {
				// console.log('inside meeting pause else ', jobInfo.meeting_pause)
				setRunTimer(false)
			}

			/**
			 * Call six minute warning modal
			 */
			openSixMinuteModal(seconds, haveCard)
		}
	}

	/**
	 * Check timings and open 6 minute warning modal
	 * @params = seconds
	 * @response : Open warning modals
	 * @author : Karan
	 */
	const openSixMinuteModal = async (seconds, haveCard) => {
		if (job) {
			sendAlertToTechnician(seconds, haveCard)
			let paidJobs = totalPaidJobs ? totalPaidJobs : 0
			if (seconds && (seconds === 1 || seconds % 60 === 0)) {
				const totalPaidJobsResponse = job ? await getTotalPaidJobs(job.id, job.customer.id, user?.id) : 0
				// console.log('Get totalPaidJobs api response.>>>>', totalPaidJobsResponse)
				paidJobs = totalPaidJobsResponse.success ? totalPaidJobsResponse.data.totalPaidJobs : 0
				console.log('openSixMinuteModal totalPaidJobs>>>>>>>>>>', paidJobs, haveCard)
			}
			if (userType === 'customer' && paidJobs === 0) {
				// console.log('openSixMinuteModal enter>>>>>>>>>>')

				setShowFreeMinutesEndPopup(false)
				setShowLastMinuteWarningPopup(false)
				setShowFreeMinutesExpiringPopup(false)
				setCloseWarningPopup(false)

				seconds = seconds ? seconds : runningSeconds
				haveCard = haveCard ? haveCard : customerHaveCard
				// console.log('haveCard >>>>>>>>', haveCard)
				if (haveCard) {
					if (seconds >= FREE_TIME_EXPIRING_SHOW_AFTER_SECONDS && seconds < TOTAL_FREE_SECONDS) {
						// setShowFreeMinutesExpiringPopup(true)
						setShowFreeMinutesExpiringPopup(false)
					}
				} else {
					if (seconds >= POPUP_SHOW_AFTER_SECONDS && seconds < TOTAL_FREE_SECONDS) {
						// console.log('Going to open end meeting warning')
						setShowFreeMinutesEndPopup(true)
						playSoundForNotification()
					} else if (seconds >= TOTAL_FREE_SECONDS && seconds < MEETING_END_TIME_SECONDS) {
						// console.log('Going to open last minute warning')
						// setShowFreeMinutesEndPopup(false)
						// soundPlayed = false
						setShowLastMinuteWarningPopup(true)
						playSoundForNotification()
					} else if (seconds >= MEETING_END_TIME_SECONDS) {
						// console.log('Going to end meeting')
						// setShowFreeMinutesEndPopup(false)
						// setShowLastMinuteWarningPopup(false)
						setCloseWarningPopup(true)
						setJitsiSessionEnd(true)
						endMeeting(jitsiApi, job, user, socket)
					}
				}
			}
		}
	}

	/**
	 * Send alert to technician about tell customer to add card
	 * @params = seconds
	 * @response : Will open alert notification for technician
	 * @author : Karan
	 */
	const sendAlertToTechnician = (seconds, haveCard) => {
		haveCard = haveCard ? haveCard : customerHaveCard
		if (userType === 'technician' && totalPaidJobs === 0 && !haveCard && !techNotified) {
			seconds = seconds ? seconds : runningSeconds
			if (seconds >= POPUP_SHOW_AFTER_SECONDS && seconds < TOTAL_FREE_SECONDS) {
				techNotified = true
				sendNotificationToTechnician()
			} else if (seconds >= TOTAL_FREE_SECONDS && seconds < MEETING_END_TIME_SECONDS) {
				techNotified = true
				sendNotificationToTechnician()
			}
		}
	}

	// Function to generate a random 5-digit number
	const generateRandomCode = () => {
		return Math.floor(10000 + Math.random() * 90000)
	}

	/**
	 * Open remote session at customer and technician side.
	 * @params = email(Type:String),userType(Type:String),socket(Type:Socket),job(Type:Object)
	 * @response : open zoho page at customer and technician side
	 * @author : Manibha
	 */
	const hitZohoRemoteSession = useCallback(
		async (email, userType, socket, job, userClick) => {
			console.log('hitZoho remote session', userClick)

			const res = await sendDataSession(email, user?.id)

			const my_session_data = JSON.parse(res)

			my_session_data['job'] = job
			socket.emit('zoho-session', my_session_data)
			// mixpanel code
			mixpanel.identify(email)
			mixpanel.track(userType + ' - Click on Remote', { JobId: job.id })

			// mixpanel code
			// console.log('>>>>> opening zoho session again again >>>>>>>>>>>')
			if (!zohoSession) {
				// console.log('>>>.entering inside if session zohoSession')
				const newWind = window.open(my_session_data['representation']['customer_url'], '_blank')
				if (!newWind || newWind.closed || typeof newWind.closed == 'undefined') {
					setShowRemoteAccessModal(true)
				}
				zohoSession = true
				setTimeout(() => {
					zohoSession = false
				}, 6000)
			}
		},
		[job],
	)

	// const hitZohoRemoteSession = useCallback(
	// 	async (email, userType, socket, job,userClick) => {
	// 		console.log('hitZoho remote session',userClick);
	// 		const res = await sendDataSession(email, user?.id)
	// 		// Generate a random 5-digit code
	// 		// const randomCode = generateRandomCode();
	// 		// const meetingInfo = {
	// 		// 	sessionType:'Support',
	// 		// 	name: 'Test Session',
	// 		// 	isPublic:false,
	// 		// 	code:randomCode,
	// 		// };
	// 		// const response = await createSession(meetingInfo,user?.id)
	// 		// console.log('response from server',response);
	// 		// const { baseUrl, meetingDetails } = response;
	// 		// let session_data = {
	// 		// 	baseUrl,
	// 		// 	meetingDetails,
	// 		// 	job // Add the job property here
	// 		// };
	// 		const my_session_data = JSON.parse(res)
	// 		setSessionData(my_session_data);
	// 		my_session_data['job'] = job
	// 		if(userClick === 'technician'){
	// 			setIsRemoteLoading(false)
	// 			console.log("zoho-session",userType,user)
	// 			setZohoUrl(baseUrl);
	// 			openModal();
	// 			socket.emit('zoho-session', session_data)
	// 		}
	// 		// socket.emit('zoho-session', my_session_data)
	// 		// mixpanel code
	// 		mixpanel.identify(email)
	// 		mixpanel.track(userType + ' - Click on Remote', { JobId: job.id })
	// 		// Open modal with Zoho URL
	// 		if (user.userType == userClick) {
	// 			setIsRemoteLoading(false)
	// 			console.log("zoho-session",userType,user)
	// 			setZohoUrl(my_session_data['representation']['customer_url']);
	// 			openModal();
	// 			socket.emit('zoho-session', my_session_data)
	// 		  }

	// 		// mixpanel code
	// 		// console.log('>>>>> opening zoho session again again >>>>>>>>>>>')
	// 		// if (!zohoSession) {
	// 		// 	console.log('>>>.entering inside if session zohoSession')
	// 		// 	const newWind = window.open(my_session_data['representation']['customer_url'], '_blank')
	// 		// 	if (!newWind || newWind.closed || typeof newWind.closed == 'undefined') {
	// 		// 		setShowRemoteAccessModal(true)
	// 		// 	}
	// 		// 	zohoSession = true
	// 		// 	setTimeout(() => {
	// 		// 		zohoSession = false
	// 		// 	}, 6000)
	// 		// }
	// 	},
	// 	[job],
	// )

	// This function is used to Toggle the twilio Chat
	const expandableToggle = () => {
		if (expandableOpen) {
			setNotificationDot(false)
			setSocketHits(0)
		}
		setExpandaleOpen(!expandableOpen)
	}

	const handelBackDrop = () => {
		const { isBackdropVisible, jobId } = isBackDropOpen
		if (job?.id === jobId && isBackdropVisible && startPaidTimerCompIsLoaded) {
			return true
		} else {
			return false
		}
	}

	// This will redirect user to main url when access token is not available  ~ Jagroop
	const redirectToGeekerPage = () => {
		window.location.href = `${MAIN_APP_URL}?chatScreen=${jobId}`
	}

	useEffect(() => {
		async function fetchData() {
			if (job?.isReturningCustomer !== undefined) {
				if (userType === 'technician' && !job?.isReturningCustomer) {
					const customerFromPartner = job?.customerPartner
					if (customerFromPartner == PARTNER_NAME) {
						const partnerDataRes = await getPartnerWithShortName(customerFromPartner)
						setShowWelcomePartnerPopup(true)
						setPartnerData(partnerDataRes)
					}
				}
			}
		}
		fetchData()
	}, [job?.customerPartner, job?.isReturningCustomer])

	const handleCloseWelcomePartnerPopup = () => {
		setShowWelcomePartnerPopup(false)
	}

	/**
	 * autoSwithTo1Hour
	 * @params : ''
	 * @author : @kirollosatef
	 */

	const autoSwithTo1Hour = async () => {
		if (userType === 'technician' && job.software.id === process.env.REACT_APP_IT_SOFTWARE_ID) {
			console.log('autoSwithTo1Hour inside function', userType, job.software.id, process.env.REACT_APP_IT_SOFTWARE_ID)
			setShowAutoSwitchPopup(true)
		}
	}
	useEffect(() => {
		console.log('autoSwithTo1Hour useEfect', showAutoSwitchPopup)
	}, [showAutoSwitchPopup])
	return (
		<>
			<div>
				<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={handelBackDrop()}>
					<div className="back-drop-css">
						<CircularProgress color="inherit" />
						<span className="back-drop-css-text">
							{userType === 'technician'
								? 'Waiting for the customer to confirm the next steps you’ve recommended.'
								: 'Please wait a few moments. Your Geek is preparing your transfer'}
						</span>
					</div>
				</Backdrop>
			</div>

			{/* <ErrorBoundary> */}
			{job && user && socket && (
				<UpdateJobModel
					showUpdateJobModal={showUpdateJobModal}
					setShowUpdateJobModal={setShowUpdateJobModal}
					showTransfterCallLoader={showTransfterCallLoader}
					setShowTransfterCallLoader={setShowTransfterCallLoader}
					setDataLoaded={setDataLoaded}
					job={job}
					user={user}
					socket={socket}
					showHireExpertCheckbox={true}
					showDeclinedReason={true}
					isTransferCall={true}
					isOpenFromCustomer={true}
					transferWithoutSubmit={transferWithoutSubmit}
					setTransferWithoutSubmit={setTransferWithoutSubmit}
					fiveMinModalAction={fiveMinModalAction}
					setFiveMinModalAction={setFiveMinModalAction}
					setModalAction={setModalAction}
					startTroubleShootModal={startTroubleShootModal}
					startswitchGeekModalModal={startswitchGeekModalModal}
					disableTransferCancelBtn={disableTransferCancelBtn}
				/>
			)}
			<Row>
				<Col md="3" lg="2">
					{job && <Header user={user} job={job} />}
					{job &&
						(job?.status === 'Inprogress' || job?.status === 'Completed' || job?.status === 'long-job') &&
						(job?.customer?.user?.id === user?.id || job?.technician?.user?.id === user?.id) && (
							<Col xs={12}>
								<Button className="btn app-btn app-btn-light-blue w-100 mt-2" onClick={() => setUploadFiles(true)}>
									<span />
									Upload Files
								</Button>
								{/* {userType === 'customer' && (
									<>
										<Button
											className="btn app-btn app-btn-light-blue w-100 mt-2 mb-2"
											onClick={() => setShowScreenConnectIframe(!showScreenConnectIframe)}
										>
											<span />
											Grant Remote Access
										</Button>
									</>
								)} */}
							</Col>
						)}
					<UploadFileModal
						job={job}
						user={user}
						setUploadFiles={setUploadFiles}
						uploadFiles={uploadFiles}
						files={files}
						setFiles={setFiles}
						socket={socket}
					/>
					{job && (
						<>
							{userType && job && user && socket && userType === 'technician' && (
								<TechnicianSidebar
									job={job}
									setJob={setJob}
									user={user}
									socket={socket}
									setShowEndmeetingButton={setShowEndmeetingButton}
									setIntialTimeVal={setIntialTimeVal}
									setShowTransferButton={setShowTransferButton}
									setEnableStartPauseTimerButton={setEnableStartPauseTimerButton}
									haveSubMin={haveSubMin}
									requiredParticipantJoined={requiredParticipantJoined}
								/>
							)}
							{userType && job && user && socket && userType === 'customer' && (
								<CustomerSidebar
									job={job}
									user={user}
									socket={socket}
									setShowEndmeetingButton={setShowEndmeetingButton}
									newCardAdded={newCardAdded}
									openAddCardModal={openAddCardModal}
									setApproveClicked={setApproveClicked}
									approveClicked={approveClicked}
									setIntialTimeVal={setIntialTimeVal}
									totalPaidJobs={totalPaidJobs}
									haveSubMin={haveSubMin}
									haveSubscription={haveSubscription}
								/>
							)}
							{socket && (
								<MeetingStatDetails
									userType={userType}
									setMicroponeStatusCustomer={setMicroponeStatusCustomer}
									microPhoneStatusCustomer={microPhoneStatusCustomer}
									setMicroponeStatusTechnician={setMicroponeStatusTechnician}
									microPhoneStatusTechnician={microPhoneStatusTechnician}
									runningSeconds={runningSeconds}
									socket={socket}
									setInternetConnectionStatus={setInternetConnectionStatus}
									phoneStatusAvailability={phoneStatusAvailability}
								/>
							)}
							{job && user && socket && (
								<Col xs="12" className="mt-3">
									<Accordion defaultExpanded={true} onChange={expandableToggle}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls="panel1a-content"
											id="panel1a-header"
											sx={{
												justifyContent: isiPad ? 'center' : isLaptopLandscape ? 'flex-start' : 'center',
											}}
										>
											<Stack spacing={2} direction="row" alignItems="center">
												<Badge
													badgeContent={socketHits}
													invisible={!showNotificationDot || expandableOpen}
													sx={{
														'& .MuiBadge-badge': {
															backgroundColor: 'red',
															fontSize: '10px',
															color: 'white',
															fontWeight: 'bold',
														},
													}}
												>
													<Typography
														variant="body1"
														sx={{
															marginRight: { xs: '5px', md: '10px' },
															fontWeight: 'bold',
															fontSize:
																isiPad || isLaptopLandscape ? '0.9rem' : { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
															'@media (max-width: 768px) and (max-height: 1024px)': {
																fontSize: '0.8rem',
															},
															'@media (max-width: 1024px) and (max-height: 1366px)': {
																fontSize: '0.6rem',
															},
														}}
													>
														{userType === 'customer' ? 'Chat With Technician' : 'Chat With Customer'}
													</Typography>
												</Badge>
											</Stack>
										</AccordionSummary>
										<AccordionDetails>
											<TwilioChatPanel
												height="26rem"
												width="100%"
												setNotificationDot={setNotificationDot}
												job={job}
												socket={socket}
												user={user}
												userType={userType}
												setSocketHits={setSocketHits}
												socketHits={socketHits}
												startChat={startChat}
											/>
										</AccordionDetails>
									</Accordion>
								</Col>
							)}

							{/* This Component will used to test the System requirement and is currently commented by Jagroop 13 July 2023 */}
							{/* <SystemRequirement /> */}
						</>
					)}
				</Col>

				<Col md="9" lg="10">
					<Row>
						{browserData.isSafariBrowser && (
							<div className="alert-div">
								<Alert
									onClose={() => {
										setBrowserData({ ...browserData, isSafariBrowser: false })
									}}
									dismissible
									className=""
								>
									<p>{browserData.safariMessage}</p>
								</Alert>
							</div>
						)}
						{job && socket && (
							<JitsiContainer
								job={job}
								user={user}
								userType={userType}
								socket={socket}
								totalPaidJobs={totalPaidJobs}
								jitsiSessionEnd={jitsiSessionEnd}
								jitsiContainerId={jitsiContainerId}
								setJitsiApi={setJitsiApi}
								setIsScreenShared={setIsScreenShared}
								// meetingPlatform={meetingPlatform}
								micPermission={micPermission}
								showMicPermissionModal={showMicPermissionModal}
								setShowMicPermissionModal={setShowMicPermissionModal}
								showRemoteAccessModal={showRemoteAccessModal}
								setShowRemoteAccessModal={setShowRemoteAccessModal}
								internetConnectionStatus={internetConnectionStatus}
								setRequiredParticipantJoined={setRequiredParticipantJoined}
								requiredParticipantJoined={requiredParticipantJoined}
								setStartChat={setStartChat}
								meetingStartedByCustomer={meetingStartedByCustomer}
								hasMeetingStarted={hasMeetingStarted}
								setPhoneStatusAvailability={setPhoneStatusAvailability}
								phoneStatusAvailability={phoneStatusAvailability}
								setIsRemoteLoading={setIsRemoteLoading}
								isRemoteLoading={isRemoteLoading}
								showWarningApproachingTheEstimatedTime={showWarningApproachingTheEstimatedTime}
								setShowWarningApproachingTheEstimatedTime={setShowWarningApproachingTheEstimatedTime}
							/>
						)}

						<Col xs="12">
							<Col className="jitsi-bottom-section my-4 p-3 radius-8">
								<Row>
									<Col lg="5" md="12">
										<MeetingInfo
											job={job}
											totalPaidJobs={totalPaidJobs}
											user={user}
											setIsModalOpen={setIsModalOpen}
											haveSubscription={haveSubscription}
											setStartTime={setStartTimerForMeeting}
											socket={socket}
											userType={userType}
										/>
									</Col>

									<Col lg="2">
										<div key={intialTimeVal} className="meeting-timer">
											<Timer
												initialTime={intialTimeVal}
												startImmediately={false}
												ref={timerRef}
												checkpoints={[
													{
														time: 60000 * 4,
														callback: () => {
															console.log('4 minutes completed')
															const secondsNow = 240
															openSixMinuteModal(secondsNow)
														},
													},
													{
														time: 60000 * 6,
														callback: () => {
															console.log('6 minutes completed')
															techNotified = false
															const secondsNow = 360
															openSixMinuteModal(secondsNow)

														},
													},
													{
														time: 60000 * 7,
														callback: () => {
															console.log('7 minutes completed')
															const secondsNow = 420
															openSixMinuteModal(secondsNow)
														},
													},
													{
														time: 60000 * 55,
														callback: () => {
															console.log('55 minutes completed')
															openFiftyFiveMinuteModal()
														},
													},
													{
														time: 60000 * 60,
														callback: () => {
															console.log('1 hour completed')
															triggerOneHourFunction() // Call your desired function
														},
													},
												]}
											>
												{() => (
													<div className="f-16">
														<Timer.Hours
															formatValue={(value) => {
																value = value > 9 ? value : value < 1 ? '00' : '0' + value
																time.hours = value || 0
																return value || '0'
															}}
														/>
														:
														<Timer.Minutes
															formatValue={(value) => {
																value = value > 9 ? value : value < 1 ? '00' : '0' + value
																time.minutes = value || 0
																return value || '0'
															}}
														/>
													</div>
												)}
											</Timer>
										</div>
									</Col>

									<Col lg="5" md="12" className=" text-left text-lg-center p-0 pt-3">
										{job && socket && (
											<MeetingControllers
												job={job}
												setJob={setJob}
												user={user}
												userType={userType}
												jitsiApi={jitsiApi}
												socket={socket}
												isScreenShared={isScreenShared}
												setIsScreenShared={setIsScreenShared}
												setJitsiSessionEnd={setJitsiSessionEnd}
												enableStartPauseTimerButton={enableStartPauseTimerButton}
												showEndmeetingButton={showEndmeetingButton}
												showTransferButton={showTransferButton}
												// hitZohoRemoteSession={hitZohoRemoteSession}
												LeaveTheMeeting={LeaveTheMeeting}
												setLeaveTheMeeting={setLeaveTheMeeting}
												setShowEndMeetingSpinner={setShowEndMeetingSpinner}
												startTroubleShootModal={startTroubleShootModal}
												startswitchGeekModalModal={startswitchGeekModalModal}
												setIsRemoteLoading={setIsRemoteLoading}
												isRemoteLoading={isRemoteLoading}
												oneHourCompleted={oneHourCompleted}
												oneHourPass={oneHourPass}
											/>
										)}

										{job && user && customerInfo && socket && userType === 'customer' && (
											<FreeMinutesEndPopup
												socket={socket}
												job={job}
												user={user}
												jitsiApi={jitsiApi}
												showFreeMinutesEndPopup={showFreeMinutesEndPopup}
												showLastMinuteWarningPopup={showLastMinuteWarningPopup}
												showFreeMinutesExpiringPopup={showFreeMinutesExpiringPopup}
												closeWarningPopup={closeWarningPopup}
												runningSeconds={runningSeconds}
												customerInfo={customerInfo}
												isScreenShared={isScreenShared}
												warningTimerRef={warningTimerRef}
												lastMinuteTimerRef={lastMinuteTimerRef}
												freeMinuteExpireTimerRef={freeMinuteExpireTimerRef}
												isModalOpen={isModalOpen}
												openAddCardModal={openAddCardModal}
											/>
										)}

										{userType === 'technician' && job && !job?.is_long_job && (
											<SuggestLongJobConversion runningSeconds={runningSeconds} job={job} />
										)}
										{/*userType === 'technician' && job && <MeetingConfirmationModal job={job} />*/}

										{job &&
											user &&
											customerInfo &&
											socket &&
											userType === 'customer' &&
											fiveMinModalAction?.customerMeetConfirmModal && (
												<CustomerMeetingConfirmationModal
													customerMeetConfirmModal={fiveMinModalAction?.customerMeetConfirmModal}
													setModalAction={setModalAction}
													job={job}
													socket={socket}
													totalPaidJobs={totalPaidJobs}
													openBackDrop={openBackDrop}
													setOpenBackDrop={setOpenBackDrop}
													startBilledMeeting={startBilledMeeting}
													user={user}
												/>
											)}
										{job && user && customerInfo && socket && userType === 'customer' && fiveMinModalAction?.switchGeekModal && (
											<SwitchGeeksModal
												showUpdateJobModal={showUpdateJobModal}
												setShowUpdateJobModal={setShowUpdateJobModal}
												switchGeekModal={fiveMinModalAction?.switchGeekModal}
												setModalAction={setModalAction}
												jitsiApi={jitsiApi}
												job={job}
												user={user}
												socket={socket}
												setLeaveTheMeeting={setLeaveTheMeeting}
												showEndMeetingSpinner={showEndMeetingSpinner}
												startThirtySeconds={startThirtySeconds}
												setStartThirtySeconds={setStartThirtySeconds}
												countdown={countdown}
												setCountdown={setCountdown}
												setOpenBackDrop={setOpenBackDrop}
												initialCustomerModalAction={initialCustomerModalAction}
												setCustomerModalAction={setCustomerModalAction}
												handleBackDropChange={handleBackDropChange}
											/>
										)}
										{job &&
											user &&
											customerInfo &&
											socket &&
											userType === 'customer' &&
											fiveMinModalAction?.troubleShootModal && (
												<LetsTroubleShootModal
													setShowUpdateJobModal={setShowUpdateJobModal}
													troubleShootModal={fiveMinModalAction?.troubleShootModal}
													setModalAction={setModalAction}
													job={job}
													socket={socket}
													totalPaidJobs={totalPaidJobs}
													setLeaveTheMeeting={setLeaveTheMeeting}
													startBilledMeeting={startBilledMeeting}
													countdownTroubleShootModal={countdownTroubleShootModal}
													setCountdownTroubleShootModal={setCountdownTroubleShootModal}
													countdown={countdown}
													initialCustomerModalAction={initialCustomerModalAction}
													setCustomerModalAction={setCustomerModalAction}
													user={user}
												/>
											)}
									</Col>
								</Row>
							</Col>
						</Col>
					</Row>
				</Col>
				{userType === 'technician' && partnerData.success && job && (
					<WelcomePartnerPopup
						partnerData={partnerData.data}
						isOpen={showWelcomePartnerPopup}
						onClose={handleCloseWelcomePartnerPopup}
						customer={job.customer.user}
					/>
				)}
				<Modal
					className=""
					footer={[
						<Button className="btn app-btn app-btn-light-blue modal-footer-btn" onClick={() => redirectToGeekerPage()}>
							<span></span>Join Again
						</Button>,
					]}
					closable={false}
					visible={tetchTokenFoundFromCookie}
					maskStyle={{ backgroundColor: '#DCE6EDCF' }}
					maskClosable={false}
				// onCancel={() => {setTetchTokenFoundFromCookie(false)}}
				>
					<h2>{SESSION_EXPIRY_MSG}</h2>
				</Modal>
				{showAutoSwitchPopup && (
					<Modal visible={showAutoSwitchPopup} maskStyle={{ backgroundColor: '#DCE6EDCF' }} footer={null} style={{ paddingButtom: '0px' }}>
						<h2
							style={{
								textAlign: 'center',
								fontSize: '24px',
								fontWeight: 'bold',
								marginBottom: '10px',
							}}
						>
							⏰ Approaching 1 hour!
						</h2>
						<p
							style={{
								fontSize: '20px',
							}}
						>
							Consider switching to Project Mode for more flexibility—work at your own pace and reconvene with your client as needed.
							Plus, it’s a more cost-effective option for the customer. Ready to make the switch?
						</p>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginBottom: '-50px',
							}}
						>
							<Button variant="text" className="later-btn" onClick={() => setShowAutoSwitchPopup(false)}>
								<span className="later-span">
									<FontAwesomeIcon icon={faThumbsUp} /> Ok
								</span>
							</Button>
						</div>
					</Modal>
				)}
				{/* {userType === 'customer' &&(		<Modal
                title="Notice"
                visible={showOneHourModal}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Create Account"
                cancelText="Close"
            >
                <p>Hope you enjoyed your free job. Please click here to create an account.</p>
            </Modal>
		)} */}
				{userType === 'customer' && (
					<Modal visible={showOneHourModal} width={800} closable={false} footer={null} className={'customer-meeting-confirmation-modal'}>
						<div className="customer-meeting-confirmation-modal-heading">
							<img src={startSmiley} className="customer-meeting-confirmation-modal-image" alt="Smiley" />
							<span className="customer-meeting-confirmation-modal-heading-text">Hope you enjoyed your free job</span>
						</div>
						<div className="text-center">
							<span className="customer-meeting-confirmation-modal-text-span">
								After Five minutes, your meeting will be ended automatically.
							</span>
						</div>
						<Button className="btn app-btn app-btn-light-blue modal-footer-btn" onClick={handleOk} style={{ backgroundColor: '#13BCBD' }}>
							<span></span>Continue
						</Button>
					</Modal>
				)}
			</Row>
			{/* </ErrorBoundary> */}

			{/* <Modal
				title="Remote Session Meeting"
				className="custom-modal-title-zoho"
				visible={showRemoteAccessModalZoho}
				onCancel={closeModal}
				footer={null}
				maskClosable={false}
				destroyOnClose={true}
				bodyStyle={{
					boxShadow: '-1px -1px 2px #FDFDFD, 2px 2px 6px #D2DBE2',
					background: '#FFFFFF',
				}}
			>
				<div className="mx-4">
					<div className="modal-text">Click below to start remote session: </div>
					<div className="modal-text">
						<a href={zohoUrl} target="_blank" rel="noopener noreferrer" onClick={() => handleZohoSessionClick(user.userType)}>
							<FontAwesomeIcon icon={faHandPointRight} className="hand-icon" /> Start Remote Session
						</a>
					</div>
				</div>
			</Modal> */}
			{showScreenConnectIframe && (
				<RemoteAccessModal
					visible={showScreenConnectIframe}
					onClose={() => setShowScreenConnectIframe(false)}
				/>
			)}
		</>
	)
}

export default Meeting
