import { Player } from '@lottiefiles/react-lottie-player'
import { useMediaQuery } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useMemo, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Countdown, { zeroPad } from 'react-countdown'
import { useTimer } from 'react-timer-hook'
import notificationSound from '../../assets/sounds/notification.mp3'
import { addCardCountDownTimer } from '../../constants'
import { useJitsiMeet } from '../../context/jitsiMeetContext'
import { getTimeDifference, openNotificationWithIcon, startTimer, updateCustomerStartMeetingResponse } from '../../utils'
import UpdateJobModel from '../Common/UpdateJobModel'
import MeetingConfirmationModal from '../Meeting/components/MeetingConfirmationModal'
import TimerTick from '../../assets/animation/Timwe.json'
import { getJobInformation } from '../../api/meeting.api'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Constant messages shown to respective user type
const MESSAGES = {
	customerSidePrimaryAlert: "You're not being billed yet. ",
	customerSideSecondaryAlert: 'Please explain your issue and timer will start when your Geek is ready to start working on your issue.',
	technicianSideSecondaryAlert: ' You’re in assessment mode ',
	customerSideMeetingPausedAlert: 'Your meeting is temporarily paused by technician and will resume shortly.',
	customerSideLongJobMeetingPausedAlert: 'You are now in a Project. You are not being billed per minute.',
	technicianSideMeetingPauseAlert: 'Your meeting is paused! Remember to resume before continuing work!',
}


const WarningMessage = ({ techName, setShowWarningApproachingTheEstimatedTime }) => {
  return (
    <div className="warning-message">
      <div className="warning-content">
				Hi {techName}, you are approaching the estimated time provided to the customer when they posted the job. Please inform the customer that completing the job will take longer than originally estimated.
      </div>
      <button 
        className="close-button" 
        onClick={() => {
					setShowWarningApproachingTheEstimatedTime(false)
					localStorage.setItem('technicianDismissedApproachingEstimatedTime', true)
				}}
        aria-label="Close warning"
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};


export const StartPaidTimerAlert = ({ userType, job, totalPaidJobs, socket, api, user, setRequiredParticipantJoined, showWarningApproachingTheEstimatedTime, setShowWarningApproachingTheEstimatedTime}) => {
	// These states are used to open Transfer Call Button ~Jagroop
	const [showUpdateJobModal, setShowUpdateJobModal] = useState(false)
	const [showTransfterCallLoader, setShowTransfterCallLoader] = useState(false)
	const [countDownTimer, setcountDownTimer] = useState(addCardCountDownTimer)
	const [dataSetLoaded, setDataLoaded] = useState(false)
	const [showFiveMinModal, setShowFiveMinModal] = useState(false)
	const [secModalIsVisible, setSecModalIsVisible] = useState(false)
	const [fiveMinHasPassed, setFiveMinHasPassed] = useState(false)
	const { openBackDrop, setOpenBackDrop, startPaidTimerCompIsLoaded, setStartPaidTimerCompIsLoaded, handleBackDropChange, isBackDropOpen } =
		useJitsiMeet()
	const [meetingStartedByTech, setMeetingStartedByTech] = useState(false)
	const expiryTimestamp = new Date()
	expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60)

	const { restart, pause } = useTimer({
		expiryTimestamp,
		onExpire: () => {
			handleRestartTimer()
		},
	})

	const handleRestartTimer = () => {
		const { isBackdropVisible, jobId } = isBackDropOpen

		if (isBackdropVisible) {
			return
		}
		if (fiveMinHasPassed) {
			return
		}

		pause()
		setShowFiveMinModal(true)
		setSecModalIsVisible(true)
		// audio.play()
		restart(expiryTimestamp)
	}

	// useEffect(() => {
	// 	if (userType == 'technician' && showFiveMinModal) {
	// 		const intervalId = setInterval(() => {
	// 			audio.play()
	// 			// console.log('Audio playing tingle')
	// 		}, 30000)
	// 		return () => clearInterval(intervalId)
	// 	}
	// }, [showFiveMinModal, userType])

	useEffect(() => {
		const { isBackdropVisible, jobId } = isBackDropOpen
		if (userType == 'technician' && jobId !== job.id) {
			localStorage.removeItem('isBackdropVisible')
		}
	}, [isBackDropOpen])

	const handleCloseMeeting = () => {
		setShowFiveMinModal(false)
		setSecModalIsVisible(false)
		restart(expiryTimestamp)
	}

	// let audio = new Audio(notificationSound)

	const startBilledMeetingByTech = async () => {
		try {
			if (userType === 'technician') {
				const jobInfoResponse = await getJobInformation(job.id, user?.id)
				const jobInfo = jobInfoResponse.success ? jobInfoResponse.data : {}
				if (!jobInfo.meetingStarted) {
					console.log('inside the continueMeeting 3')
					startTimer(jobInfo, false, socket, totalPaidJobs)
					setShowUpdateJobModal(false)
					setOpenBackDrop(false)
					setRequiredParticipantJoined(false)
					let dataToUpdate = 'automatically Start Billed Meeting after 30 seconds'
					updateCustomerStartMeetingResponse(jobInfo.id, dataToUpdate, user.id)
					openNotificationWithIcon('success', 'Meeting timer started.')
				}
			}
		} catch (error) {
			console.error('Error in startBilledMeeting:', error)
		}
	}

	useEffect(() => {
		try {
			if (meetingStartedByTech && userType === 'technician') {
				const timeoutId = setTimeout(() => {
					startBilledMeetingByTech()
				}, 30000)

				return () => {
					clearTimeout(timeoutId)
				}
			}
		} catch (error) {
			console.error('Error in startTroubleShootModal useEffect:', error)
		}
	}, [meetingStartedByTech])

	useEffect(() => {
		; (async () => {
			// console.log('countdown minutes from useEffect',{startTime : job?.paid_meeting_alert_time})
			if (job.paid_meeting_alert_time) {
				const timeDifference = getTimeDifference(job.paid_meeting_alert_time)
				setcountDownTimer(addCardCountDownTimer - timeDifference)
			}
			setStartPaidTimerCompIsLoaded(true)
		})()
	}, [])

	/**
	 * @description : This function will start paid timer and off the alert.
	 * @author  : Jagroop
	 */
	const startJobTimer = () => {
		try {
			// If paid timer is not started then we will start that otherwise we will off alert with notification
			if (!job.meetingStarted) {
				startTimer(job, api, socket, totalPaidJobs, userType)
				setRequiredParticipantJoined(false)
				// openNotificationWithIcon('success', 'Meeting is resumed, billing starts now.');
			} else {
				setRequiredParticipantJoined(false)
				openNotificationWithIcon('info', 'Your billing is already started')
			}
		} catch (error) {
			console.log('JitsiContainer ::: Error in starting paid timer', error)
			setRequiredParticipantJoined(false)
		}
	}

	/**
	 * @description : This function is called when technician click on Transfer Call. This will open modal of transfer call.
	 * @author : Jagroop
	 */
	const updateJobDetails = async () => {
		setShowUpdateJobModal(!showUpdateJobModal)
		// mixpanel code//
		mixpanel.identify(user.email)
		mixpanel.track(`Technician - Click on Tranfer Call before Paid Timer Started`, { JobId: job.id })
		// mixpanel code//
	}

	return (
		<React.Fragment>
			{job?.meeting_pause ? (
				<>
					{userType == 'technician' ? (
						<TechnicianAlertForPausedJob job={job} userType={userType} socket={socket} user={user} />
					) : (
						<CustomerAlert job={job} />
					)}
				</>
			) : (
				<>
					{userType == 'technician' ? (
						<TechnicianAlert
							setMeetingStartedByTech={setMeetingStartedByTech}
							fiveMinHasPassed={fiveMinHasPassed}
							setFiveMinHasPassed={setFiveMinHasPassed}
							handleRestartTimer={handleRestartTimer}
							setSecModalIsVisible={setSecModalIsVisible}
							secModalIsVisible={secModalIsVisible}
							showFiveMinModal={showFiveMinModal}
							setShowFiveMinModal={setShowFiveMinModal}
							handleCloseMeeting={handleCloseMeeting}
							startJobTimer={startJobTimer}
							updateJobDetails={updateJobDetails}
							countDownTimer={countDownTimer}
							userType={userType}
							job={job}
							socket={socket}
							isBackDropOpen={isBackDropOpen}
							user={user}
							showWarningApproachingTheEstimatedTime={showWarningApproachingTheEstimatedTime}
							setShowWarningApproachingTheEstimatedTime={setShowWarningApproachingTheEstimatedTime}
						/>
					) : (
						<CustomerAlert job={job} />
					)}
					<UpdateJobModel
						showUpdateJobModal={showUpdateJobModal}
						setShowUpdateJobModal={setShowUpdateJobModal}
						showTransfterCallLoader={showTransfterCallLoader}
						setShowTransfterCallLoader={setShowTransfterCallLoader}
						setDataLoaded={setDataLoaded}
						job={job}
						user={user}
						socket={socket}
						showHireExpertCheckbox={true}
						isTransferCall={true}
					/>
				</>
			)}
		</React.Fragment>
	)
}

/**
 * @description : This is the aleart component shown to  user type customer or may be to anonymous users without role who joined meeting when invited.
 * @author : Jagroop
 */
const CustomerAlert = ({ job }) => {
	return (
		<div className="alert-message-join-header-meeting-style">
			<Alert key="danger" variant="danger" className="alert-message-join-meeting-style">
				{job.meeting_pause && job?.long_job_with_minutes === 'yes' ? (
					<>
						<b>{MESSAGES.customerSideMeetingPausedAlert}</b>
					</>
				) : job.meeting_pause && !job.is_long_job ? (
					<>
						<b>{MESSAGES.customerSideMeetingPausedAlert}</b>
					</>
				) : (
					<>
						{job.status === 'long-job' && job?.long_job_with_minutes === 'no' ? (
							<>
								<b>{MESSAGES.customerSideLongJobMeetingPausedAlert}</b>
							</>
						) : (
							<>
								<b>{MESSAGES.customerSidePrimaryAlert}</b>
								{MESSAGES.customerSideSecondaryAlert}
							</>
						)}
					</>
				)}
			</Alert>
		</div>
	)
}

/**
 * @description : This component is shown to technician when they pause the meeting
 * @author : Jagroop
 */
const TechnicianAlertForPausedJob = ({ job, userType, socket, user }) => {
	
	// This function Resume the Paused Meeting
	const unPauseMeeting = () => {
		const isPaused = JSON.parse(localStorage.getItem(`timerPaused-${job.id}`)) === true
		console.log('handleStartPauseTimer isPaused', isPaused)
		const updatedPausedState = !isPaused
		console.log('handleStartPauseTimer updatedPausedState', updatedPausedState)

		localStorage.setItem(`timerPaused-${job.id}`, updatedPausedState.toString())

		const data = {}
		data['jobId'] = job.id
		data['userType'] = userType
		data['total_pause_seconds'] = job.total_pause_seconds

		socket.emit('Resume:timer', data)
		// mixpanel code
		mixpanel.identify(user.email)
		mixpanel.track('Technician - Start Timer', { Data: data })
		// mixpanel code
	}

	return (
		<div className="alert-message-join-header-meeting-style">
			{job?.long_job_with_minutes !== 'no' || job?.long_job_with_minutes === undefined ? (
				<Alert key="danger" variant="danger" className="alert-message-join-meeting-style d-flex justify-content-center align-self-center">
					<div>
						<b>{MESSAGES.technicianSideMeetingPauseAlert}</b>
					</div>
					<div className="meeting-start-btn-style">
						<Button variant="start-job-alert-btn-style" onClick={unPauseMeeting}>
							Resume
						</Button>
					</div>
				</Alert>
			) : null}
		</div>
	)
}

const CountDown = ({ autoStart, useTimer, renderer, timesUp }) =>
	useMemo(
		() => (
			<Countdown
				date={Date.now() + useTimer}
				renderer={renderer}
				key={useTimer}
				controlled={false}
				autoStart={autoStart}
				onComplete={timesUp}
			/>
		),
		[useTimer],
	)

/**
 * @description : This is the aleart component shown to  user type technician only.
 * @author : Jagroop
 */

const TechnicianAlert = ({
	fiveMinHasPassed,
	setFiveMinHasPassed,
	setSecModalIsVisible,
	handleRestartTimer,
	showFiveMinModal,
	secModalIsVisible,
	setShowFiveMinModal,
	handleCloseMeeting,
	startJobTimer,
	updateJobDetails,
	countDownTimer,
	userType,
	job,
	socket,
	isBackDropOpen,
	user,
	setMeetingStartedByTech,
	showWarningApproachingTheEstimatedTime,
	setShowWarningApproachingTheEstimatedTime,
}) => {
	// const [showFiveMinModal, setShowFiveMinModal] = useState(false)
	const isiPad = useMediaQuery('(max-width: 1024px)')
	const takeActionForMeeting = (actionType) => {
		if (actionType == 'transfer-call') {
			updateJobDetails()
		} else {
			startJobTimer()
		}
	}

	const renderer = useMemo(
		() =>
			({ minutes, seconds }) => {
				// console.log("countdown minutes", { minutes, seconds });
				return (
					<span>
						{zeroPad(minutes)}:{zeroPad(seconds)}
					</span>
				)
			},
		[],
	)

	const timesUp = () => {
		setShowFiveMinModal(true)
		setSecModalIsVisible(false)
		setFiveMinHasPassed(true)
	}

	const startJob = () => {
		setShowFiveMinModal(true)
		fiveMinHasPassed ? setSecModalIsVisible(false) : setSecModalIsVisible(true)
	}

	return (
		<>
			{showWarningApproachingTheEstimatedTime && 
				<div className="alert-message-join-header-meeting-style" style={{ margin: 'auto' }}>
					<WarningMessage techName={job.technician.user.firstName} setShowWarningApproachingTheEstimatedTime={setShowWarningApproachingTheEstimatedTime} />
				</div>
			}
			{userType === 'technician' && job && showFiveMinModal && !isBackDropOpen.isBackdropVisible && !showWarningApproachingTheEstimatedTime && (
				<MeetingConfirmationModal
					job={job}
					showFiveMinModal={showFiveMinModal}
					setShowFiveMinModal={setShowFiveMinModal}
					takeActionForMeeting={takeActionForMeeting}
					secModalIsVisible={secModalIsVisible}
					handleCloseMeeting={handleCloseMeeting}
					socket={socket}
					user={user}
					setMeetingStartedByTech={setMeetingStartedByTech}
				/>
			)}
			{!showWarningApproachingTheEstimatedTime && (
				<div className="alert-message-join-header-meeting-style">
					<Alert key="danger" variant="danger" className="alert-message-join-meeting-style d-flex justify-content-center align-self-center">
						<div className="End-Assesment-mode">
							<div className="End-Assesment-mode-inner">
								<Player
									autoplay={true}
									loop={true}
									keepLastFrame={true}
									src={TimerTick}
									className="exam-fail-animation"
									speed={0.5}
									style={{ height: '50px' }}
								/>
								<span>
									<b>
										{`Billing paused `}
										<CountDown useTimer={countDownTimer} renderer={renderer} timesUp={timesUp} />
									</b>
									{MESSAGES.technicianSideSecondaryAlert}
								</span>
							</div>
							<div className="meeting-start-btn-style">
								<div className="end-ass-btn" onClick={() => startJob()}>
									<span className="end-ass-btn-inner">End Assessment mode</span>
								</div>
							</div>
						</div>
					</Alert>
				</div>
			)}
		</>
	)
}
